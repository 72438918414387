import tracking from '../shared/tracking'

export const MAX_RATING = 5
export const RATINGS = [1, 2, 3, 4, 5]

export const getRatingText = rating =>
  rating === RATINGS[0]
    ? 'Very Bad'
    : rating === RATINGS[1]
    ? 'Bad'
    : rating === RATINGS[2]
    ? 'Good'
    : rating === RATINGS[3]
    ? 'Very Good'
    : rating === RATINGS[4]
    ? 'Amazing'
    : ''

export const isMaxRating = rating => rating === MAX_RATING

export const trackEventReview = (product, review, reviewId) => {
  // If the assortment equals 0, is a Meal if not is a Product
  const eventName = product.assortment === 0 ? 'Meal Rated' : 'Product Reviewed'
  tracking.track(eventName, {
    product_id: product.id,
    name: product.name,
    product_premium: product.premium_special,
    review_body: review.review,
    rating: review.rating,
    review_id: reviewId
  })
}
