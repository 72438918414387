import { SECTIONS, SECTIONS_PRIORITIES } from './constants'

export function useProgressBarStatus(profiles) {
  const profilesCount = profiles?.length
  const singleProfile = profilesCount === 1

  function getProgressData() {
    let percentage = 0
    let firstIncompleteRule
    SECTIONS_PRIORITIES.forEach(rule => {
      if (rule.profileIndex < profilesCount) {
        if (checkSection(rule.section, rule.profileIndex)) {
          percentage += singleProfile
            ? rule.percentage.single
            : rule.percentage.double
        } else if (!firstIncompleteRule) {
          firstIncompleteRule = rule
        }
      }
    })
    return { rule: firstIncompleteRule, percentage }
  }

  function checkSection(section, profileIndex) {
    if (!profiles?.[profileIndex] || profileIndex >= profilesCount) return true
    return isSectionCompleted(section, profiles[profileIndex])
  }

  function isSectionCompleted(name, profile) {
    switch (name) {
      case SECTIONS.ALLERGENS:
        return profile.allergies?.length > 0 || profile.noSelection.allergies
      case SECTIONS.DIET:
        return profile.diets?.length > 0
      case SECTIONS.LIFESTYLE:
        return profile.lifestyle?.length > 0 || profile.noSelection.lifestyle
      case SECTIONS.PROTEINS:
        return profile.proteins?.length > 0 || profile.noSelection.proteins
      case SECTIONS.INGREDIENTS:
        return (
          profile.ingredients?.length > 0 || profile.noSelection.ingredients
        )
      case SECTIONS.CUISINES:
        return profile.cuisines?.length > 0 || profile.noSelection.cuisines
      default:
        return false
    }
  }

  return { getProgressData, isSectionCompleted }
}
