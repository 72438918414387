import { createAction } from 'redux-actions'

export const clearAllFilters = createAction('ALL_FILTERS_CLEARED')
export const setFilters = createAction('FILTERS_CHANGED')
export const setChefFilter = createAction('CHEF_FILTER_CHANGED')
export const setSortBy = createAction('SORT_BY_CHANGED')
export const clearSortBy = createAction('CLEAR_SORT_BY')
export const setSticky = createAction('SET_STICKY_FILTERS')
export const setIsDefaultMenuBy = createAction('SET_IS_DEFAULT_MENU_BY')
export const setProfilePreferenceFilter = createAction(
  'SET_PROFILE_PREFERENCE_FILTER_CHANGED'
)

export const getSortingTypesStart = createAction('GET_SORTING_TYPES_STARTED')
export const getSortingTypesSuccess = createAction('GET_SORTING_TYPES_SUCCEED')
export const getSortingTypesFail = createAction('GET_SORTING_TYPES_FAILED')
export const setSortingMenuSelected = createAction('SET_SORTING_MENU_SELECTED')

export const getFilterTypesStart = createAction('GET_FILTER_TYPES_STARTED')
export const getFilterTypesSuccess = createAction('GET_FILTER_TYPES_SUCCEED')
export const getFilterTypesFail = createAction('GET_FILTER_TYPES_FAILED')

export const setFilteredMeals = createAction('SET_FILTERED_MEALS')
export const setCategoryId = createAction('SET_CATEGORY_ID')
export const setCategoryLabel = createAction('SET_CATEGORY_LABEL')

export const getQuickFiltersStart = createAction('GET_QUICK_FILTERS_STARTED')
export const getQuickFiltersSuccess = createAction('GET_QUICK_FILTERS_SUCCEED')
export const getQuickFiltersFail = createAction('GET_QUICK_FILTERS_FAILED')

export const setMenuSelected = createAction('SET_MENU_SELECTED')
export const setTabSelected = createAction('SET_TAB_SELECTED')
export const setMenuItems = createAction('SET_MENU_ITEMS')

export const setFullMenuQuickfilters = createAction(
  'SET_FULL_MENU_QUICKFILTERS'
)

export const setFullMenuProductTypeSelected = createAction(
  'SET_FULL_MENU_PRODUCT_TYPE_FILTER'
)
