import { createSelector } from 'reselect'
import { pathOr } from 'ramda'
import {
  FILTER_IDS,
  SORTING_NAMES
} from 'src/modules/MyPlan/components/MenuBy/Filters/constants'

export const getCurrentFilters = createSelector(
  state => state.filters,
  filters => ({
    diets: filters.diets,
    preferences: filters.preferences,
    proteins: filters.proteins,
    cuisines: filters.cuisines
  })
)

export const getCurrentChefFilter = createSelector(
  state => state.filters,
  filters => filters.chef || []
)

export const hidePremiumFilter = createSelector(
  state => state.filters,
  filters => filters.hidePremiumFilter || false
)

export const getCurrentSortBy = createSelector(
  state => state.filters,
  filters => filters.sortBy
)

export const getCurrentSelectedProfilePreferences = createSelector(
  state => state.filters,
  filters => filters.selectedProfilePreference
)

export const getCurrentQuickFilter = createSelector(
  state => state.filters,
  filters => filters.quickFilterSelected
)

export const getQuickFilters = createSelector(
  state => state.filters,
  filters => filters.quickFilters
)

export const getSearchInput = createSelector(
  state => state.filters,
  filters => filters.searchInput
)

export const getSortingTypes = state =>
  pathOr(null, ['filters', 'sortingTypes'], state)

export const getMenuSortingSelected = state =>
  pathOr(null, ['filters', 'menuSortingSelected'], state)

export const getFilteredMeals = state =>
  pathOr([], ['filters', 'filteredMeals'], state)

export const getCategoryId = state =>
  pathOr([], ['filters', 'selectedCategoryId'], state)

export const getCategoryLabel = state =>
  pathOr([], ['filters', 'selectedCategoryLabel'], state)

export const getAlreadyRequestedMeals = state => {
  const res = pathOr([], ['menu', 'alreadyRequestedMeals', 'meals'], state)
  return res
}

export const getMenuSelectedLabel = state =>
  pathOr([], ['filters', 'menuSelected'], state)

export const getPossibleFilters = state => {
  const mealFilters = getMealsFilters(state)

  if (!Object.entries(mealFilters).length) {
    return []
  }

  const possibleFilters = []

  Object.entries(mealFilters).forEach(([key, value]) => {
    value.forEach(v => {
      const filter = {
        diets: [],
        preferences: [],
        proteins: []
      }

      filter[key] = [v]

      possibleFilters.push(filter)
    })
  })

  return possibleFilters
}

export const getFullMenuProductTypeFilters = state =>
  pathOr([], ['filters', 'fullMenuProductTypes'], state)

export const getFullMenuProductTypeSelectedFilters = state =>
  pathOr([], ['filters', 'fullMenuProductTypesSelected'], state)

export const getMealsFilters = state => {
  if (!state.filters.filterTypes) {
    return {
      diets: [],
      preferences: [],
      proteins: [],
      cuisines: []
    }
  }

  return {
    diets: state.filters.filterTypes.diets,
    preferences: state.filters.filterTypes.preferences,
    proteins: state.filters.filterTypes.proteins,
    cuisines: state.filters.filterTypes.cuisines
  }
}

export const getSettings = state =>
  pathOr([], ['api', 'subscription', 'settings'], state)

export const showSortByPopUp = createSelector([getSettings], settings => {
  const showSortByPopu = settings.find(
    i => i.key === 'showsortbypopuptomazeuxtest'
  )
  return !!showSortByPopu && showSortByPopu.value !== '0'
})

export const getMenuSelected = createSelector(
  state => state.filters,
  filters => filters.menuSelected
)

export const getFullMenuCategories = createSelector(
  state => state.filters,
  filters => filters.menuItems
)

export const getFullMenuSelectedCategory = createSelector(
  state => state.filters,
  filters => filters.menuSelected
)

export const getTabSelected = createSelector(
  state => state.filters,
  filters => filters.tabSelected
)

export const getMenuItems = createSelector(
  state => state.filters,
  filters => filters.menuItems
)

export const getSelectedCategoryLabel = createSelector(
  state => state.filters,
  filters => filters.selectedCategoryLabel
)

export const getFullMenuProductTypes = createSelector(
  state => state.filters,
  filters => filters.fullMenuProductTypes
)

export const notFilterAndSortingApplied = state =>
  state.filters.diets?.length === 0 &&
  state.filters.preferences?.length === 0 &&
  state.filters.proteins?.length === 0 &&
  state.filters.cuisines?.length === 0 &&
  state.filters.chef?.length === 0 &&
  (!state.filters.quickFilterSelected ||
    state.filters.quickFilterSelected?.id === FILTER_IDS.ALL) &&
  state.filters.menuSelected?.id === FILTER_IDS.ALL &&
  state.filters.tabSelected?.id === FILTER_IDS.ALL &&
  !state.filters.searchInput &&
  state.filters.sortBy?.[0].name === SORTING_NAMES.MY_PREFERENCES
