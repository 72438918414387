import { FILTER_IDS } from '../../../modules/MyPlan/components/MenuBy/Filters/constants'

import { ReactComponent as Pescatarian } from '../../../assets/svg/diets/pescatarian.svg'
import { ReactComponent as Flexitarian } from '../../..//assets/svg/diets/flexitarian.svg'
import { ReactComponent as Omnivore } from '../../..//assets/svg/diets/omnivore.svg'
import { ReactComponent as Vegan } from '../../../assets/svg/diets/vegan.svg'
import { ReactComponent as Vegetarian } from '../../../assets/svg/diets/vegetarian.svg'
import { ReactComponent as Mediterranean } from '../../../assets/svg/diets/mediterranean.svg'
import { ReactComponent as Paleo } from '../../../assets/svg/diets/paleo.svg'
import { ReactComponent as Keto } from '../../../assets/svg/diets/keto.svg'

import { ReactComponent as Beef } from '../../../assets/svg/proteins/beef.svg'
import { ReactComponent as Lamb } from '../../../assets/svg/proteins/lamb.svg'
import { ReactComponent as Chicken } from '../../../assets/svg/proteins/chicken.svg'
import { ReactComponent as Fish } from '../../../assets/svg/proteins/fish.svg'
import { ReactComponent as Pork } from '../../../assets/svg/proteins/pork.svg'
import { ReactComponent as Shellfish } from '../../../assets/svg/proteins/shellfish.svg'
import { ReactComponent as VeganProtein } from '../../../assets/svg/proteins/vegan.svg'
import { ReactComponent as VegetarianProtein } from '../../../assets/svg/proteins/vegetarian.svg'
import { ReactComponent as Poultry } from '../../../assets/svg/proteins/poultry.svg'

import { ReactComponent as LowCarbs } from '../../../assets/svg/preferences/lowCarbs.svg'
import { ReactComponent as LowSugar } from '../../../assets/svg/preferences/lowSugar.svg'
import { ReactComponent as LowCalories } from '../../../assets/svg/preferences/low-calories.svg'
import { ReactComponent as DairyFree } from '../../../assets/svg/preferences/dairy-free.svg'
import { ReactComponent as SoyFree } from '../../../assets/svg/preferences/soy-free.svg'
import { ReactComponent as GlutenFree } from '../../../assets/svg/preferences/gluten-free.svg'
import { ReactComponent as LowFat } from '../../../assets/svg/preferences/low-fat.svg'
import { ReactComponent as LowSodium } from '../../../assets/svg/preferences/low-sodium.svg'
import { ReactComponent as NutFree } from '../../../assets/svg/preferences/nut-free.svg'
import { ReactComponent as HighProtein } from '../../../assets/svg/preferences/high-protein.svg'
import { ReactComponent as Spicy } from '../../../assets/svg/preferences/spicy.svg'

import { ReactComponent as YouveTried } from '../../../assets/icons/menuby/you_ve-tried.svg'
import { ReactComponent as Favorites } from '../../../assets/icons/menuby/favorites.svg'
import { ReactComponent as HidePremium } from '../../../assets/svg/fullMenu/hide-premium.svg'

import MediterraneanCuisine from '../../../assets/icons/menuby/mediterranean.png'
import AsianCuisine from '../../../assets/icons/menuby/asian.png'
import AmericanCuisine from '../../../assets/icons/menuby/american.png'
import ItalianCuisine from '../../../assets/icons/menuby/italian.png'
import MexicanCuisine from '../../../assets/icons/menuby/mexican.png'
import IndianCuisine from '../../../assets/icons/menuby/indian.png'
import MiddleEasternCuisine from '../../../assets/icons/menuby/middleEastern.png'
import LatinAmericanCuisine from '../../../assets/icons/menuby/latin-american.png'
import AfricanCuisine from '../../../assets/icons/menuby/african.png'
import CaribeanCuisine from '../../../assets/icons/menuby/caribean.png'
import FrenchCuisine from '../../../assets/icons/menuby/french.png'
import EuropeanCuisine from '../../../assets/icons/menuby/european.png'

export const DIETS_ICONS_MAPPING = {
  pescatarian: <Pescatarian />,
  flexitarian: <Flexitarian />,
  omnivore: <Omnivore />,
  vegan: <Vegan />,
  vegetarian: <Vegetarian />,
  mediterranean: <Mediterranean />,
  paleo: <Paleo />,
  keto: <Keto />
}

export const PROTEINS_ICONS_MAPPING = {
  beef: <Beef />,
  lamb: <Lamb />,
  chicken: <Chicken />,
  fish: <Fish />,
  pork: <Pork />,
  shellfish: <Shellfish />,
  vegan: <VeganProtein />,
  vegetarian: <VegetarianProtein />,
  other_poultry: <Poultry />,
  poultry: <Poultry />
}

export const PREFERENCES_ICONS_MAPPING = {
  low_carbs: <LowCarbs />,
  low_sugar: <LowSugar />,
  dairy_free: <DairyFree />,
  soy_free: <SoyFree />,
  gluten_free: <GlutenFree />,
  low_fat: <LowFat />,
  less_than_600_calories: <LowCalories />,
  low_sodium: <LowSodium />,
  nut_free: <NutFree />,
  high_protein: <HighProtein />,
  spicy: <Spicy />
}

export const CUISINES_ICONS_MAPPING = {
  mediterranean: MediterraneanCuisine,
  asian: AsianCuisine,
  american: AmericanCuisine,
  italian: ItalianCuisine,
  mexican: MexicanCuisine,
  indian: IndianCuisine,
  middle_eastern: MiddleEasternCuisine,
  african: AfricanCuisine,
  caribbean: CaribeanCuisine,
  french: FrenchCuisine,
  latin_american: LatinAmericanCuisine,
  european: EuropeanCuisine
}

export const HIGHLIGHTS_ICONS_MAPPING = {
  youHaveTried: <YouveTried />,
  favorites: <Favorites />,
  hidePremium: <HidePremium />
}

export const FILTERS = {
  PROTEINS: 'proteins',
  DIETS: 'diets',
  PREFERENCES: 'preferences',
  CUISINES: 'cuisines',
  CHEFS: 'chefs',
  PRODUCT_TYPE: 'categories',
  NON_PREMIUM: 'Hide Premium'
}

export const SORTING_FILTERS = {
  MY_PREFERENCES: 'My preferences',
  DEFAULT: 'Newest to oldest',
  ALL: 'My preferences'
}

export const FME_SORTING_FILTERS = {
  MY_PREFERENCES: 'My preferences',
  AVG_CUSTOMER_REVIEW: 'Avg. Customer review',
  RECOMMENDED_IN_CATEGORY: 'Recommended in Category',
  CUSTOMER_RATING: 'Customer Rating'
}

export const FILTERS_SECTIONS_TITLES = {
  [FILTERS.PROTEINS]: 'Protein type',
  [FILTERS.DIETS]: 'Diets',
  [FILTERS.PREFERENCES]: 'Preferences',
  [FILTERS.CUISINES]: 'Cuisines',
  [FILTERS.CHEFS]: 'Chefs',
  [FILTERS.PRODUCT_TYPE]: 'Categories',
  [FILTERS.NON_PREMIUM]: 'Premium'
}

export const MENU_BY_ADVANCED_FILTER_MAP = {
  [FILTER_IDS.PROTEIN]: FILTERS.PROTEINS,
  [FILTER_IDS.DIET]: FILTERS.DIETS,
  [FILTER_IDS.PREFERENCES]: FILTERS.PREFERENCES,
  [FILTER_IDS.CUISINE]: FILTERS.CUISINES,
  [FILTER_IDS.CHEF]: FILTERS.CHEFS
}

export const ALLERGENS_IDS = {
  DAIRY_FREE: 2,
  SOY_FREE: 5,
  TREENUT_FREE: 1,
  PEANUT_FREE: 14
}

export const PREFERENCES_IDS = {
  DAIRY_FREE: 1,
  SOY_FREE: 4,
  NUT_FREE: 10
}
