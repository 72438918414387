const getInitials = fullName => {
  if (!fullName) {
    return ''
  }

  const [firstNameInitial, lastNameInitial] = fullName
    .split(' ')
    .filter(e => e) // Filter empties
    .slice(0, 2) // Ger first letter

  return [
    ...(firstNameInitial ? [firstNameInitial.trim()[0]] : ['']),
    ...(lastNameInitial ? [lastNameInitial.trim()[0]] : [''])
  ]
    .join('')
    .trim()
}

const getUserFirstName = fullName => {
  if (!fullName) {
    return ''
  }

  return fullName.split(' ')[0].trim()
}

export { getInitials, getUserFirstName }
