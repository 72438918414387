import * as actions from './actions'
import {
  fetchUserPreferencesData,
  fetchUserProfilesPreferences,
  removeUserProfileRequest,
  saveUserPreferencesData
} from '../../api/sduiService'
import { getProfilesData } from '..'

export const getPreferencesData = () => dispatch => {
  dispatch(actions.fetchPreferencesData())
  fetchUserPreferencesData()
    .then(res => res.json())
    .then(data => {
      dispatch(
        actions.fetchPreferencesDataSucceed({
          options: data?.options,
          user: data?.user
        })
      )
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.fetchPreferencesDataFailed())
    })
}

export const getUserProfilesPreferences = () => dispatch => {
  dispatch(actions.fetchUserProfilesPreferences())
  fetchUserProfilesPreferences()
    .then(res => res.json())
    .then(data => {
      dispatch(actions.fetchUserProfilesPreferencesSucceed(data))
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.fetchUserProfilesPreferencesFailed())
    })
}

export const savePreferencesData = payload => dispatch => {
  dispatch(actions.savePreferencesData())
  saveUserPreferencesData(payload)
    .then(res => res.json())
    .then(() => {
      dispatch(actions.savePreferencesDataSucceed())
      dispatch(getUserProfilesPreferences())
      // to keep store updated in the subs-back format, todo: remove this api call and use new format everywhere
      dispatch(getProfilesData)
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.savePreferencesDataFailed())
    })
}

export const removeUserProfile = payload => dispatch => {
  dispatch(actions.removeUserProfile())
  removeUserProfileRequest(payload)
    .then(res => res.json())
    .then(() => {
      dispatch(actions.removeUserProfileSucceed())
      dispatch(getUserProfilesPreferences())
      // to keep store updated in the subs-back format, todo: remove this api call and use new format everywhere
      dispatch(getProfilesData)
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.removeUserProfileFailed())
    })
}

export const setMealWish = payload => dispatch => {
  dispatch(actions.setMealWish(payload))
}

export const setMealAvoid = payload => dispatch => {
  dispatch(actions.setMealAvoid(payload))
}
