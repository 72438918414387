import { CuiModal } from 'front-lib'
import { useEffect, useState } from 'react'

import { ChangePlanForm } from './components/ChangePlanForm'
import { useChangePlanModal } from 'src/hooks/useChangePlanModal'

import './ChangePlanModal.scss'

export const ChangePlanModal = () => {
  const [title, setTitle] = useState('')
  const { isOpen, isUpgrade, close } = useChangePlanModal()

  useEffect(() => {
    if (isUpgrade) {
      setTitle('Upgrade your plan')
    } else {
      setTitle('Change your plan')
    }
  }, [isUpgrade])

  return (
    <CuiModal
      className="change-plan-modal"
      isOpen={isOpen}
      onRequestClose={close}>
      <div className="change-plan-modal--title">{title}</div>
      <div className="change-plan-modal--form">
        <ChangePlanForm />
      </div>
    </CuiModal>
  )
}
