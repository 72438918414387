import classnames from 'classnames'
import { SafeImage } from 'src/componentsV2/SafeImage/SafeImage'
import './ChefAvatar.scss'

const ChefAvatar = ({
  image,
  fallbackImage,
  name,
  onClick,
  className,
  size = 'medium',
  inlineStyles = {}
}) => {
  return (
    <div
      className={classnames(className, 'chef-avatar', `chef-avatar--${size}`)}
      onClick={onClick}
      style={inlineStyles}
      role="button">
      <SafeImage
        imageSrc={image}
        fallbackSrc={fallbackImage}
        alt={name}
        className={`chef-avatar__img chef-avatar__img--${size}`}
      />
      {!!name && (
        <div className={`chef-avatar__name chef-avatar__name--${size}`}>
          {name}
        </div>
      )}
    </div>
  )
}

export { ChefAvatar }
