import moment from 'moment'
import { find, propEq, filter } from 'ramda'
import Numeral from 'numeral'

import { PLATFORM } from '../constants/application'

import mobileAndTabletCheck from './mobileAndTabletcheck'
import { ADDONS_CATEGORIES, MEALS_CATEGORY } from 'src/constants/menu'
const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE

// FeatureFlags list
export const featureFlags = {
  useShareModal: 'useShareModal',
  iosNativeReferralButton: 'iosNativeReferralButton',
  isReferralPageBEnabled: 'useReferralExperiment',
  abReferralVariant: 'abReferralVariant'
}

export const formatNumber = number => {
  return (+number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const formatNumberOne = number => {
  return (+number).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const formatPrice = price => {
  if (!price) return null
  return `+ ${Numeral(price).format('$0,0.00')}`
}

export const formatFixedPrice = price => {
  if (price === undefined || price === null) {
    return ''
  }
  if (price < 0.01) {
    return '$0.00'
  } else {
    return `${Numeral(price).format('$0,0.00')}`
  }
}

export const formatFixedPriceWithNegatives = price => {
  if (price === undefined || price === null) {
    return ''
  }

  return `${Numeral(price).format('$0,0.00')}`
}

export const isBoolean = value => {
  return typeof value === 'boolean'
}

const gettimeDiff = date => {
  if (!date || !date.isValid()) {
    return null
  }
  return moment.duration(date.diff(moment()))
}

export const getStringTimeTo = date => {
  const diff = gettimeDiff(date)
  if (!diff) {
    return ''
  }
  return `${diff.get('days')} ${
    diff.get('days') === 1 ? 'day' : 'days'
  }, ${diff.get('hours')} hours, ${diff.get('minutes')} minutes`
}

export const getStringTimeToShort = date => {
  const diff = gettimeDiff(date)
  if (!diff) {
    return ''
  }
  let ret = []
  if (diff.get('days') > 0) {
    ret.push(`${diff.get('days')} ${diff.get('days') === 1 ? 'day' : 'days'}`)
  }
  if (diff.get('hours') > 0) {
    ret.push(
      `${diff.get('hours')} ${diff.get('hours') === 1 ? 'hour' : 'hours'}`
    )
  }
  if (ret.length === 0) {
    ret.push(date.format('h A'))
  }

  return ret.join(', ')
}

export const formatTime = time => moment(time, 'HH:mm').format('h:mmA')

/**
 * Checks wheter an element has a given parent.
 *
 * @param elem (target element)
 * @param value (value of class, id, dataAttribute.. to compare)
 * @param type (by default is most common use case class, can be passed id, data-attrName)
 *
 * @return bool
 */
export const nodeInParent = (elem, value, type = 'class') => {
  if (
    elem === null ||
    (elem.classList && elem.classList.contains('content')) ||
    elem.tagName === 'BODY'
  ) {
    return false
  }

  const dataAttr = type.substring(5)

  switch (type) {
    case 'class':
      if (false === elem.classList.contains(value)) {
        return nodeInParent(elem.parentNode, value, type)
      }
      break

    case 'id':
      if (value !== elem.id) {
        return nodeInParent(elem.parentNode, value, type)
      }
      break

    case /data(-\w+)/.test(type):
      if (value !== elem.dataset[dataAttr]) {
        return nodeInParent(elem.parentNode, value, type)
      }
      break

    default:
      return true
  }

  return true
}

export const isBeforeCutoff = cutoffDay => {
  const today = moment()
  return (
    today.isBefore(cutoffDay, 'day') ||
    (today.isSame(cutoffDay, 'day') && today.isSameOrBefore(cutoffDay, 'hour'))
  )
}

export const getUpcomingOrdersAfterCutoff = upcomingOrders => {
  const orders =
    upcomingOrders &&
    upcomingOrders.reduce((accum, upcomingOrder) => {
      if (
        upcomingOrder &&
        upcomingOrder.order &&
        !isBeforeCutoff(upcomingOrder.cutoff.time)
      ) {
        return [...accum, upcomingOrder]
      }
      return accum
    }, [])

  return orders
}

export const getUpcomingOrdersBeforeCutoff = upcomingOrders => {
  const orders =
    upcomingOrders &&
    upcomingOrders.reduce((accum, upcomingOrder) => {
      if (
        upcomingOrder &&
        upcomingOrder.order &&
        isBeforeCutoff(upcomingOrder.cutoff.time)
      ) {
        return [...accum, upcomingOrder]
      }
      return accum
    }, [])

  return orders
}

export const checkIfMealExistIntoWishListProfiles = (
  mealId = null,
  profiles = []
) => {
  if (!profiles || profiles.length === 0) return false

  const iteration = profile => {
    return find(propEq('mealId', mealId))(profile.mealWishes) !== undefined
  }
  return filter(iteration, profiles).length > 0
}

export const getTotalMealsOrder = order => {
  return order
    ? order.items &&
        order.items.reduce((accum, item) => {
          return accum + item.qty
        }, 0)
    : '-'
}

export const getDevice = () =>
  mobileAndTabletCheck() ? 'Mobile Web' : 'Web Desktop'

export const getReferralConfigDevice = () =>
  mobileAndTabletCheck() ? 'Mobile' : 'Desktop'

export const getPlatform = () =>
  mobileAndTabletCheck() ? PLATFORM.mobileWeb : PLATFORM.webDesktop

const testRegexForDevice = regex => {
  return (
    regex.test(navigator.userAgent) ||
    regex.test(navigator.platform) ||
    (navigator.userAgentData && regex.test(navigator.userAgentData.platform))
  )
}

export const isAndroidDevice = () => {
  const regex = /Android/i
  return testRegexForDevice(regex)
}

export const isIOSDevice = () => {
  const regex = /(iPad)|(iPod)|(iPhone)/i
  return testRegexForDevice(regex)
}

export const isMacOSDevice = () => {
  const regex = /(macintosh)/i
  return testRegexForDevice(regex)
}

export const isWindowsOSDevice = () => {
  const regex = /(windows)/i
  return testRegexForDevice(regex)
}

export const isLinuxOSDevice = () => {
  const regex = /(linux)/i
  return testRegexForDevice(regex)
}

export const getDeviceOS = () => {
  if (isAndroidDevice()) return 'Android'
  if (isIOSDevice()) return 'iOS'
  if (isMacOSDevice()) return 'MacOS'
  if (isWindowsOSDevice()) return 'Windows'
  if (isLinuxOSDevice()) return 'Linux'
  return undefined
}

/**
 * @deprecated Use src/hooks/useIsMobile.js instead
 */
export const isMobileDevice = () => mobileAndTabletCheck()
export const getPlatformText = isMobile => (isMobile ? 'mobile' : 'desktop')

const RESURRECTION_PATH = window.location.pathname
  .replace(/^\/([^]*).*$/, '$1')
  .split('-')[0]

export const isResurrectionLanding = RESURRECTION_PATH === 'resurrection'

export const getTimeslot = ({ start, end }) => {
  return {
    start,
    end,
    label: `${formatTime(start)} - ${formatTime(end)}`
  }
}

export const getShareModalSetting = userSettings => {
  const useShareModalSetting =
    userSettings &&
    userSettings.find(item => item.key === featureFlags.useShareModal)

  const useShareModal =
    useShareModalSetting && useShareModalSetting.value === 'true' ? true : false

  return useShareModal
}

export const getReferralExperimentSettigns = (userSettings, featureflag) => {
  const useReferralExperimentSetting =
    userSettings && userSettings.find(item => item.key === featureflag)

  if (useReferralExperimentSetting) {
    switch (featureflag) {
      case featureFlags.isReferralPageBEnabled:
        if (
          useReferralExperimentSetting &&
          useReferralExperimentSetting.value === 'true'
        ) {
          return true
        }
        return false
      case featureFlags.abReferralVariant:
        return useReferralExperimentSetting.value
      default:
        return false
    }
  }

  return false
}

export const getIosNativeReferralButtonSetting = userSettings => {
  const useIosNativeReferralButtonSetting =
    userSettings &&
    userSettings.find(item => item.key === featureFlags.iosNativeReferralButton)

  const useIosNativeReferralButton =
    useIosNativeReferralButtonSetting && useIosNativeReferralButtonSetting.value
      ? true
      : false

  return useIosNativeReferralButton
}

export const capitalize = str => {
  return str
    .trim()
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(' ')
}

export const isEven = value => value % 2 === 0

export const noop = () => {}

export const isIOSApp = () => {
  const regex = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i
  return testRegexForDevice(regex)
}

export const buildImgUrl = (baseUrl, path, name) => {
  const urlRegex = /^(http|https):\/\/[^ "]+$/
  if (urlRegex.test(name)) return name
  return (
    (baseUrl ?? IMG_URL_SOURCE) +
    `${path ?? ''}${name ?? ''}`.replaceAll('//', '/')
  )
}

export const mealsAreEquals = (meal1, meal2) => {
  if (!meal1 || !meal2) return false
  return (
    compareMealAttribute(meal1.id, meal2.id) ||
    compareMealAttribute(meal1.magento_id, meal2.magento_id) ||
    compareMealAttribute(meal1.magentoId, meal2.magentoId) ||
    compareMealAttribute(meal1.entityId, meal2.entityId) ||
    compareMealAttribute(meal1.entity_id, meal2.entity_id) ||
    compareMealAttribute(meal1.inventoryId, meal2.inventoryId)
  )
}

const compareMealAttribute = (id1, id2) => {
  if (!id1 || !id2) return false
  return id1.toString() === id2.toString()
}

export const calculateMealCategory = product => {
  if (!!product.meals || !!product.items) {
    return getBundleCategory(product)
  }

  return product.categoryId || product.category_id
}

const getBundleCategory = bundle => {
  const bundleMeals = bundle.meals || bundle.items?.map(i => i.product)
  const categoryId = bundleMeals.some(
    meal => calculateMealCategory(meal) === MEALS_CATEGORY
  )
    ? MEALS_CATEGORY
    : ADDONS_CATEGORIES[0]

  return categoryId
}

export const formatNumberToOrdinal = number => {
  return number > 0
    ? number.toString() +
        ['th', 'st', 'nd', 'rd'][
          (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
        ]
    : ''
}

export const getPauseResumeDay = (lastSkips, format = 'MM/DD/YYYY') => {
  if (lastSkips && lastSkips.length > 0) {
    return moment(lastSkips[0].date)
      .add(1, 'day')
      .format(format)
  }

  return null
}

export const getUniqueChefs = meals => {
  if (!meals) return []
  const chefIds = new Set()
  const uniqueChefs = []

  meals.forEach(meal => {
    const chefId = meal?.chef?.id
    if (chefId && !chefIds.has(chefId)) {
      chefIds.add(chefId)
      uniqueChefs.push(meal.chef)
    }
  })

  return uniqueChefs
}

export const optimizeImage = ({ url, width }) => {
  if (!url) return ''
  return `${url}?w=${width}&fm=webp`
}
