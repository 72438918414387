import classNames from 'classnames'

import { Plan } from 'src/types/plan'

import './PlanOptionCard.scss'

interface Props {
  plan: Plan
  selected: boolean
  type: 'button' | 'plain'
}

export function PlanOptionCard({ plan, selected, type }: Props) {
  const { price, mealsPerDelivery, deliveries } = plan
  const mealsPerWeek = mealsPerDelivery * deliveries
  const pricePerMeal = price / mealsPerWeek

  const cardClassNames = classNames('plan-option--card', {
    selected: selected
  })

  return (
    <div className={cardClassNames}>
      {type === 'button' && (
        <div className="option-card--button">
          <div />
        </div>
      )}
      <div className="option-card--content">
        <div className="text">
          <div className="meals-per-week">
            {mealsPerWeek} Meals <span>/ week</span>
          </div>
          <div className="price-per-meal">${pricePerMeal} per meal</div>
        </div>
        <div className="text">
          <div className="price">${price}</div>
          <div className="fees-and-taxes">Taxes & fees</div>
        </div>
      </div>
    </div>
  )
}
