import { handleActions, createAction } from 'redux-actions'

export const openOrderDetailsOverlay: ({
  deliveryDate
}: {
  deliveryDate: string
}) => any = createAction('OPEN_ORDER_DETAILS_OVERLAY')
export const closeOrderDetailsOverlay: () => any = createAction(
  'CLOSE_ORDER_DETAILS_OVERLAY'
)

export interface State {
  isOpen: boolean
  deliveryDate?: string
}

export const orderDetailsOverlayReducer = handleActions(
  {
    [openOrderDetailsOverlay as any]: (
      state: State,
      action: { payload: { deliveryDate: string } }
    ): State => {
      return {
        isOpen: true,
        deliveryDate: action.payload.deliveryDate
      }
    },
    [closeOrderDetailsOverlay as any]: (): State => {
      return {
        isOpen: false
      }
    }
  },
  {
    isOpen: false
  } as State
)
