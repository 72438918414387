import { useEffect, useState } from 'react'
import { useFlag } from '@unleash/proxy-client-react'
import { useSetAtom } from 'jotai'

import { FF_SKIP_DEFLECTION_WITH_PROMO } from 'src/constants/feature-flags'
import { useGetAvailableCreditsAndPromotions } from 'src/api/creditsAndPromotions/getAvailableCreditsAndPromotions'
import { deflectionWithActivePromoModalAtom } from 'src/atoms/modals/deflectionWithPromoActiveModal.atom'

export function useDeflectionWithPromoStrategy() {
  const [creditsAndPromotions, setCreditsAndPromotions] = useState({
    credits: '0',
    promotions: []
  })
  const featureEnabled = useFlag(FF_SKIP_DEFLECTION_WITH_PROMO)
  const [
    getAvailableCreditsAndPromotions,
    { data }
  ] = useGetAvailableCreditsAndPromotions()
  const setDeflectionWithActivePromoModal = useSetAtom(
    deflectionWithActivePromoModalAtom
  )

  useEffect(() => {
    if (featureEnabled) {
      getAvailableCreditsAndPromotions()
    }
    // eslint-disable-next-line
  }, [featureEnabled])

  useEffect(() => {
    let creditsAndPromotionsAvailable = {}

    if (data?.creditsAndPromotionsPageCreditsResume.creditsAvailable) {
      creditsAndPromotionsAvailable = {
        ...creditsAndPromotionsAvailable,
        credits: data.creditsAndPromotionsPageCreditsResume.creditsAvailable
      }
    }

    if (
      data?.creditsAndPromotionsPagePromotionsTab.promotions &&
      data?.creditsAndPromotionsPagePromotionsTab.promotions.length > 0
    ) {
      const promotions = data?.creditsAndPromotionsPagePromotionsTab.promotions.filter(
        promotion => promotion.active
      )
      creditsAndPromotionsAvailable = {
        ...creditsAndPromotionsAvailable,
        promotions
      }
    }

    setCreditsAndPromotions({
      ...creditsAndPromotions,
      ...creditsAndPromotionsAvailable
    })
    // eslint-disable-next-line
  }, [data])

  function triggerStrategy({
    type,
    payload = {},
    onContinue = () => {}
  }: {
    type: 'skip' | 'pause' | 'cancel'
    payload: any
    onContinue: () => void
  }) {
    const { credits, promotions } = creditsAndPromotions
    const noPromos = promotions.length === 0
    const noCredits = credits === '0'

    const noDeflectionStrategy = noPromos && noCredits
    // the startegy for pause and skip only applies if user has promotion
    // credits are not included in this strategies
    const noStrategyForSkipAndPause =
      noPromos && (type === 'skip' || type === 'pause')

    if (noDeflectionStrategy || noStrategyForSkipAndPause) {
      onContinue()
      return
    }

    setDeflectionWithActivePromoModal({
      isOpen: true,
      type,
      payload,
      credits,
      promotions
    })
  }

  return {
    enabled: featureEnabled,
    triggerStrategy
  }
}
