import { CuiButton } from 'front-lib'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFlag } from '@unleash/proxy-client-react'

import {
  isPrepaidPlanSelector,
  getAllowPlanChange
} from 'src/redux-api-bridge/selectors'
import { getUserCanPause } from 'src/modules/MyAccount/components/Settings/selectors'
import { CancelSubscription } from '../../CancelSubscription/CancelSubscription'
import { isIOSApp } from 'src/utils/utils'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { canCancelSubscriptionSelector } from '../../CancelSubscription/selector'
import {
  FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON,
  FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT
} from 'src/constants/feature-flags'
import { useSetAtom } from 'jotai'
import { howCanISkipModalAtom } from 'src/atoms/modals/howCanISkipModal.atom'

export function PausePlanInfo({ onPause }) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const setHowCanISkipModal = useSetAtom(howCanISkipModalAtom)
  const isPrepaidPlan = useSelector(isPrepaidPlanSelector)
  const canCancelSubscription = useSelector(canCancelSubscriptionSelector)
  const userCanPause = useSelector(getUserCanPause)
  const allowPlanChange = useSelector(getAllowPlanChange)
  const ffDisableCancelSubscriptionButton = useFlag(
    FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON
  )
  const cancelSubscriptionIntoMyAccount = useFlag(
    FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT
  )
  const disableButton =
    (ffDisableCancelSubscriptionButton && !canCancelSubscription.result) ||
    !allowPlanChange

  function handleHowCanISkip() {
    setHowCanISkipModal({ isOpen: true })
  }

  return (
    <div className="cookunity__setting-section-container">
      {!isPrepaidPlan && (
        <div className="cookunity__setting-section-text-container">
          <span className="cookunity__setting-section-title">
            {t('pages.my_plan.need_a_break')}{' '}
            <span className="link" onClick={handleHowCanISkip}>
              {t('pages.my_plan.how_can_i_skip.title')}
            </span>
          </span>
          <span className="cookunity__setting-section-subtitle">
            {t('pages.my_plan.traveling')}
          </span>
        </div>
      )}
      {isPrepaidPlan && (
        <div className="cookunity__setting-section-text-container">
          <span className="cookunity__setting-section-subtitle">
            {t('pages.my_plan.prepaid')}
          </span>
        </div>
      )}
      <div className="cookunity__setting-section-buttons-container">
        {!isPrepaidPlan && userCanPause && (
          <CuiButton
            fill={isMobile ? 'outline' : 'clear'}
            className="cookunity__setting-section-buttons-primary"
            onClick={onPause}>
            {t('pages.my_plan.pause_plan_action')}
          </CuiButton>
        )}
        {!isIOSApp() && !cancelSubscriptionIntoMyAccount && (
          <CancelSubscription pageName={'My Plan'} disabled={disableButton} />
        )}
      </div>
    </div>
  )
}
