import { useFlag } from '@unleash/proxy-client-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAtom } from 'jotai'

import { canCancelSubscriptionSelector } from 'src/pages/myPlan/components/CancelSubscription/selector'
import {
  FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT,
  FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON
} from 'src/constants/feature-flags'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { getUpcomingDays } from 'src/redux-api-bridge'
import { isIOSApp } from 'src/utils/utils'
import { HowCanISkipModal } from './components/HowCanISkipModal/HowCanISkipModal'
import { PauseDeflectionModal } from 'src/components/PauseDeflectionModal/PauseDeflectionModal'
import { CancelSubscriptionWarning } from '../CancelSubscription/CancelSubscriptionWarning'
import { UnpausePlanSettings } from './components/UnpausePlanSetting'
import { PausePlanForm } from './components/PausePlanForm'
import { pauseDeflectionModalAtom } from 'src/atoms/modals/pauseDeflectionModal.atom'
import { PausePlanInfo } from './components/PausePlanInfo'
import { howCanISkipModalAtom } from 'src/atoms/modals/howCanISkipModal.atom'
import { useLastSkips } from 'src/hooks/useLastSkips'
import { getAllowPlanChange, getUserType } from 'src/redux-api-bridge/selectors'

import './PausePlanSettings.scss'

export function PausePlanSettings() {
  const dispatch = useDispatch()
  const { query } = useQueryParams()

  const [pauseDeflectionModal] = useAtom(pauseDeflectionModalAtom)
  const [howCanISipModal, setHowCanISkipModal] = useAtom(howCanISkipModalAtom)

  const canCancelSubscription = useSelector(canCancelSubscriptionSelector)
  const [showPausePlanSection, setShowPausePlanSection] = useState(false)
  const { planIsPaused } = useLastSkips()

  const { allowPlanChange } = useSelector(getAllowPlanChange)
  const { userType } = useSelector(getUserType)

  const ffDisableCancelSubscriptionButton = useFlag(
    FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON
  )
  const cancelSubscriptionIntoMyAccount = useFlag(
    FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT
  )

  useEffect(() => {
    dispatch(getUpcomingDays())
  }, [dispatch])

  useEffect(() => {
    // Cancel page has a "pause plan" button whose rely on this page to pause the plan.
    if (query.get('pause-plan') !== null) {
      setShowPausePlanSection(true)
    }

    if (query.get('skip-education') !== null) {
      setHowCanISkipModal({ isOpen: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  if (planIsPaused) {
    return <UnpausePlanSettings />
  }

  const showWarningDisableCancelButton =
    ffDisableCancelSubscriptionButton &&
    !canCancelSubscription.result &&
    canCancelSubscription.completed

  return (
    <>
      {pauseDeflectionModal.isOpen && <PauseDeflectionModal />}
      {howCanISipModal.isOpen && <HowCanISkipModal />}
      {showPausePlanSection ? (
        <PausePlanForm
          onCancel={() => {
            setShowPausePlanSection(false)
          }}
        />
      ) : (
        <PausePlanInfo
          onPause={() => {
            setShowPausePlanSection(true)
          }}
        />
      )}
      <div className={'cookunity__setting-section-cancellation-warning'}>
        {!isIOSApp() &&
          !cancelSubscriptionIntoMyAccount &&
          (!allowPlanChange ? (
            <CancelSubscriptionWarning
              allowPlanChange={false}
              isUserB2C={userType === 1 ? true : false}
            />
          ) : (
            showWarningDisableCancelButton && (
              <CancelSubscriptionWarning
                isUserB2C={userType === 1 ? true : false}
              />
            )
          ))}
      </div>
    </>
  )
}
