import classnames from 'classnames'
import {
  CuiMealFavorite,
  CuiMealActions,
  CuiTag,
  CuiMenuImage,
  CuiMealCardLoading,
  CuiMealSummary
} from 'front-lib'
import { generateChefImageUrl } from '../../../components/ChefCardRebranding/utils'
import {
  getAddButtonText,
  getCharacteristicsL1,
  getCharacteristicsL2,
  getMainTags,
  getRatingCharacteristic
} from '../utils'
import { useFlag } from '@unleash/proxy-client-react'
import {
  FF_MENU_RECIPES,
  FF_NEWNESS_IN_MENU,
  FF_USE_CHEF_SERVICE,
  FF_USE_MEALS_LITE
} from '../../../constants/feature-flags'
import { trackMealInBundleDetailClick } from '../../../utils/tracking'
import { ChefAvatar } from '../components/ChefAvatar/ChefAvatar'
import { CardTitle } from '../components/CardTitle/CardTitle'
import { CharacteristicsL1 } from '../components/CharacteristicsL1/CharacteristicsL1'
import { CharacteristicsL2 } from '../components/CharacteristicsL2/CharacteristicsL2'
import { formatFixedPrice } from '../../../utils/utils'
import { getCategoryId } from '../../../redux-api-bridge/filters/selectors'

import './RegularMealCard.scss'
import Dislike from '../../../assets/icons/slash-dark.png'
import { useMealFavorite } from 'src/hooks/useMealFavorite'
import { getIsFlexUserPlan } from 'src/modules/MyAccount/components/Settings/selectors'
import { useSelector } from 'react-redux'
import {
  CHEF_SKU_PROMOTION,
  SIDE_PROMOTION_TYPE,
  mapMenuPromotion
} from 'src/hooks/useMenuPromotion'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { isAddOn } from 'src/modules/MyPlan/utils'
import { optimizeImage } from '../../../utils/utils'

const RegularMealCard = ({
  meal,
  quantity,
  onAddItem,
  onRemoveItem,
  planIsCompleted,
  isExtra,
  onChefClick,
  onLikeMeal,
  onUnLikeMeal,
  isLikeMarked,
  onMealClick,
  isLoading,
  isEditable,
  index,
  showFavoriteButtons = false,
  fullPathChefImage = '',
  withCharacteristics = false,
  showSeeDetails = false,
  isHome = false,
  noExtraMealPlanIsCompleted = false
}) => {
  const useMealsLiteFF = useFlag(FF_USE_MEALS_LITE)
  const isFlexUser = useSelector(getIsFlexUserPlan)

  const isMobile = useIsMobile()
  const shouldUseMobileCard = isMobile && isHome
  const { isLiked, toggleFavorite } = useMealFavorite({
    mealId: meal.magento_id || meal.id,
    isLikeMarked,
    onLike: onLikeMeal,
    onUnlike: onUnLikeMeal
  })
  const categoryId = useSelector(getCategoryId)
  const showCharacteristics =
    isEditable || showFavoriteButtons || withCharacteristics
  const isBackInTheMenuEnabled = useFlag(FF_NEWNESS_IN_MENU)
  const recipesEnabled = useFlag(FF_MENU_RECIPES)

  const useChefService = useFlag(FF_USE_CHEF_SERVICE)

  const noExtraMealPlanStatus =
    !noExtraMealPlanIsCompleted || (noExtraMealPlanIsCompleted && quantity > 0)

  const chefImage =
    meal?.chef?.profileImageUrl ||
    (useChefService && fullPathChefImage) ||
    generateChefImageUrl({
      firstName: meal.chef_firstname,
      lastName: meal.chef_lastname,
      isCloseUp: true,
      isMobile: false
    })

  const mealPromotion =
    meal.promotions?.length > 0 ? mapMenuPromotion(meal.promotions[0]) : null

  const strikethroughPrice = mealPromotion
    ? mealPromotion.type === SIDE_PROMOTION_TYPE
      ? formatFixedPrice(meal.price)
      : mealPromotion.type === CHEF_SKU_PROMOTION &&
        (planIsCompleted || isAddOn(meal))
      ? formatFixedPrice(meal.price)
      : null
    : null

  const outOfStock = isEditable && meal.stock <= 0
  const showActions = isEditable && !outOfStock
  const showSummary = !isEditable && quantity > 0

  const onFavoriteClick = () => {
    toggleFavorite()
  }

  const onChefClickHandler = () => {
    !!onChefClick && onChefClick()
  }

  if (isLoading) {
    return <CuiMealCardLoading />
  }

  const _onAddItem = () => {
    onAddItem(meal)
  }

  const _onRemoveItem = () => {
    onRemoveItem(meal)
  }
  const characteristics_l1 = getCharacteristicsL1(meal, 3)
  const characteristics_l2 = getCharacteristicsL2(meal, 2, categoryId, false)
  const ratingCharacteristic = getRatingCharacteristic(meal)

  const mainTags = getMainTags(
    meal,
    3,
    isBackInTheMenuEnabled,
    recipesEnabled,
    !!mealPromotion && mealPromotion
  )

  const clickAndPreventDefault = (e, fn) => {
    e.stopPropagation && e.stopPropagation()
    e.preventDefault && e.preventDefault()
    fn()
  }
  const onSeeDetailsClick = () => {
    trackMealInBundleDetailClick()
    onMealClick()
  }

  const idToRedirect = useMealsLiteFF
    ? meal.inventoryId || meal.magento_id
    : meal.magento_id || meal.inventoryId
  return (
    <a
      href={`/products/${idToRedirect}?edit=true`}
      className={classnames('card-v2', {
        'card-v2--sold-out': outOfStock,
        'extra-small': shouldUseMobileCard
      })}
      onClick={e => clickAndPreventDefault(e, onMealClick)}>
      <div className="card-v2__chef__image-container">
        {!shouldUseMobileCard && (
          <ChefAvatar
            image={optimizeImage({ url: chefImage, width: 30 })}
            fallbackImage={meal.full_path_chef_image}
            onClick={e => clickAndPreventDefault(e, onChefClickHandler)}
            name={`${meal.chef_firstname || ''} ${meal.chef_lastname || ''}`}
            className="card-v2__chef"
            size="medium"
          />
        )}
        <div className="card-v2__img-wrapper">
          <div className="card-v2__tags-wrapper">
            {mainTags.map(tag => (
              <CuiTag
                key={tag.label}
                color={tag.color}
                fill="solid"
                size="small"
                className={classnames('card-v2__tag', {
                  'card-v2__tag-with-icon': !!tag.icon
                })}>
                {tag.icon}
                <span>{tag.label}</span>
              </CuiTag>
            ))}
          </div>
          {onLikeMeal && onUnLikeMeal && (
            <CuiMealFavorite
              checked={isLiked}
              onClick={e => clickAndPreventDefault(e, onFavoriteClick)}
              className={classnames('card-v2__favorite', {
                'card-v2__favorite--empty': !isLiked
              })}
            />
          )}
          <CuiMenuImage
            primary_image={optimizeImage({
              url:
                meal.primaryImageUrl ||
                meal.imageUrl ||
                meal.full_path_meal_image,
              width: 500
            })}
            secondary_image={(meal.media && meal.media.secondaryImage) || null}
            className="card-v2__img"
          />
        </div>
      </div>
      <div className="card-v2__details">
        {shouldUseMobileCard && (
          <ChefAvatar
            image={chefImage}
            fallbackImage={meal.full_path_chef_image}
            onClick={e => clickAndPreventDefault(e, onChefClickHandler)}
            name={`${meal.chef_firstname || ''} ${meal.chef_lastname || ''}`}
            className="card-v2__chef"
            size="extra-small"
          />
        )}
        <CardTitle
          title={meal.name}
          subtitle={shouldUseMobileCard ? undefined : meal.short_description}
          onClick={e => clickAndPreventDefault(e, onMealClick)}
          size={isMobile ? (isHome ? 'extra-small' : 'large') : 'medium'}
        />
        {showCharacteristics && (
          <div className="card-v2__characteristics">
            {!!ratingCharacteristic && (
              <CharacteristicsL2
                list={[ratingCharacteristic]}
                classNames={'card-v2__characteristics__rating'}
              />
            )}
            {characteristics_l1.length > 0 && (
              <CharacteristicsL1
                list={characteristics_l1}
                mealId={meal.id}
                size="medium"
              />
            )}
            {characteristics_l2.length > 0 && (
              <CharacteristicsL2 list={characteristics_l2} size="medium" />
            )}
          </div>
        )}
        {showActions && (isExtra || noExtraMealPlanStatus) && (
          <div className="card-v2__actions-container">
            <div onClick={e => clickAndPreventDefault(e, () => {})}>
              <CuiMealActions
                priceText={getAddButtonText(
                  meal,
                  planIsCompleted,
                  isExtra,
                  isFlexUser,
                  noExtraMealPlanIsCompleted
                )}
                strikethroughPrice={strikethroughPrice}
                quantity={quantity}
                add={_onAddItem}
                remove={_onRemoveItem}
                isEditable={isEditable}
                meal={meal}
                index={index}
                reachedPlanSize={noExtraMealPlanIsCompleted && !isExtra}
              />
            </div>
          </div>
        )}
        {outOfStock && <div className="card-v2__sold-out">SOLD OUT</div>}
        {showFavoriteButtons && onLikeMeal && onUnLikeMeal && (
          <div className="like_buttons">
            <CuiMealFavorite
              className="button-like"
              onClick={e => clickAndPreventDefault(e, onLikeMeal)}
            />
            <button
              className="button-like"
              onClick={e => clickAndPreventDefault(e, onUnLikeMeal)}>
              <img src={Dislike} alt="dislike" />
            </button>
          </div>
        )}
        {showSummary && <CuiMealSummary quantity={quantity} />}
        {showSeeDetails && (
          <div
            role="button"
            className="see-details"
            onClick={e => clickAndPreventDefault(e, onSeeDetailsClick)}>
            See details
          </div>
        )}
      </div>
    </a>
  )
}

export { RegularMealCard }
