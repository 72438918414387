import { pathOr } from 'ramda'

export const getPreferencesOptionsSelector = state =>
  pathOr(null, ['preferences', 'options'], state)

export const getPreferencesUserSelector = state =>
  pathOr(null, ['preferences', 'user'], state)

export const isLoadingPreferences = state =>
  pathOr(false, ['preferences', 'isLoading'], state)

export const isErroredPreferences = state =>
  pathOr(false, ['preferences', 'isError'], state)

export const isSavingPreferences = state =>
  pathOr(false, ['preferences', 'isSaving'], state)

export const isErroredSavingPreferences = state =>
  pathOr(false, ['preferences', 'savingError'], state)

export const getMealWishes = state =>
  pathOr(false, ['preferences', 'mealWishes'], state)

export const getMealAvoids = state =>
  pathOr(false, ['preferences', 'mealAvoids'], state)
