import { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import { parse, stringify } from 'qs'
import { useAuth0 } from '@auth0/auth0-react'
import { CuiButton, CuiHeader, CuiIcon } from 'front-lib'
import { pathOr } from 'ramda'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { setCookie, getCookie } from '../../utils/browserCookies'
import { isMobileDevice } from '../../utils/utils'
import { RESURRECTION_PATH_COOKIE_NAME } from '../../constants/application'
import Loader from '../../common/Loader'
import Background from '../../assets/img/resurrectionHeroNew.jpg'
import Section1 from '../../assets/img/resurrectionSection1.png'
import Section2 from '../../assets/img/resurrectionSection2.png'
import Section3 from '../../assets/img/resurrectionSection3.png'
import Modal from '../../components/NewModal'
import MetaTags from '../../components/MetaTags'
import HeroCui from '../../components/HeroCui'
import TextImageRowCui from '../../components/TextImageRowCui'
import { device } from '../../shared/styles/mediaQueries'
import { FF_RESURRECTION_FUNNEL } from 'src/constants/feature-flags'
import { useVariant, useFlagsStatus } from '@unleash/proxy-client-react'
import {
  trackResurrectionButtonClicked,
  trackResurrectionFunnelExperiment,
  trackResurrectionLandingPage
} from 'src/utils/tracking'
import { MealCardSlider } from 'src/components/MealCardSlider/MealCardSlider'
import { RegularMealCard } from 'src/componentsV2/MenuMealCard/RegularMealCard/RegularMealCard'

const SIGNUP_URL = process.env.REACT_APP_SIGNUP_URL

const ResurrectionContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;

    @media (max-width: 920px) {
      padding: 0px;
    }
  }

  h2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 54px;
    text-align: center;
    color: #000000;
    margin: 64px 0px 64px 0;
    box-sizing: border-box;
    width: 63%;

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 28px;
      line-height: 32px;
      margin: 24px 0px;
      width: 100%;
      padding: 10px;
    }
  }

  .top-bar-resurrection {
    background: #7ad7a7;
    text-align: center;
    width: 100%;
    padding: 0 10px;
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
    }

    @media (max-width: 790px) {
      display: none;
    }
  }
`

const FooterContainer = styled.div`
  background: black;
  padding: 115px 0 60px;
  width: 100%;
  text-align: center;

  .text-footer {
    font-family: 'TT Norms Pro';
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 61px 0 0;
    width: 100%;

    @media (max-width: 1024px) {
      width: 243px;
      margin: 61px auto 0;
    }
  }

  .text-info-container {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    width: 400px;
    margin: 0 auto 40px;

    @media (max-width: 1024px) {
      width: 80%;
    }
  }

  .text-info-footer {
    font-size: 16px;
    font-family: 'TT Norms Pro';
    line-height: 24px;
    display: block;
    margin: 0px;
  }

  .action-button-banner {
    display: flex;
    justify-content: center;
  }
`

const BannerTextBold = styled.p`
  font-family: 'Source Serif Pro';
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 16px;
`

const ExpiredModal = styled.div`
  h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    color: #7ad7a7;
  }
  p {
    line-height: 1.6em;
    font-size: 18px;
  }
  a {
    font-weight: bold;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 72px;

  @media ${device.mobile} {
    gap: 0px;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${device.mobile} {
    > * {
      margin-bottom: 85px;
    }
  }
`

const MealsSliderContainer = styled.div`
  width: 90%;
  margin: 0 auto;

  h2 {
    font-family: 'Rossanova';
    width: 100%;
    text-align: center;
    font-size: 38px;
    margin: 10px;
  }

  @media ${device.mobile} {
    width: 100%;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-family: TT Norms Pro;
  font-weight: 700;
  gap: 8px;
  width: 130px;
`

const Footer = ({ restartAction, text, conditionsText }) => (
  <FooterContainer>
    <div className="container">
      <BannerTextBold dangerouslySetInnerHTML={{ __html: text }} />
      <div className="text-info-container">
        <p className="text-info-footer">
          You can change your plan, pause, or cancel at any time.
        </p>
        <p className="text-info-footer">
          {conditionsText ||
            `Offer will only apply to deliveries within the first 4 consecutive weeks from the day of activation.`}
        </p>
      </div>
      <div className="action-button-banner">
        <CuiButton onClick={restartAction} size="large">
          Resubscribe Now
        </CuiButton>
      </div>
    </div>

    <p className="text-footer">
      &copy; {moment().format('Y')} CookUnity | support@cookunity.com | 1
      888.221.8187
    </p>
  </FooterContainer>
)

const Header = ({ restartAction }) => (
  <div style={{ width: '100%' }}>
    <CuiHeader
      showLogo
      endSecondary={
        !isMobileDevice() && (
          <CuiButton color="light" fill="solid" onClick={restartAction}>
            Resubscribe Now
          </CuiButton>
        )
      }
    />
  </div>
)

const Info = ({ image, title, text, action, reverse }) => (
  <TextImageRowCui image={image} reverse={reverse}>
    <p className="rebrand lead">{title}</p>
    <p className="rebrand" dangerouslySetInnerHTML={{ __html: text }} />
    {action}
  </TextImageRowCui>
)

const DishBannerResurrection = ({
  restartAction,
  text,
  browserTitle,
  heroTitle,
  heroSubtitle,
  heroImageUrl
}) => (
  <div style={{ width: '100%' }}>
    <MetaTags
      title={browserTitle || `Let's Start fresh. | ${text}`}
      description={text}
    />
    <HeroCui halfHero image={heroImageUrl || Background}>
      <h1 className="rebrand">
        {heroTitle || (
          <>
            Let's start <span>fresh</span>
          </>
        )}
      </h1>
      <p className="rebrand">
        {heroSubtitle || (
          <>
            Come back and enjoy more meals, more chefs,
            <br /> more deliciousness!
          </>
        )}
      </p>
      <h2 className="rebrand" dangerouslySetInnerHTML={{ __html: text }} />
      <CuiButton onClick={restartAction} size="large">
        Resubscribe Now
      </CuiButton>
      {/* <p className="rebrand small">
        Exclusive offer for users who have not claimed this discount in the last
        90 days.
      </p> */}
    </HeroCui>
  </div>
)

const ResurrectionPage = ({
  actions,
  subscriptionInfo,
  isReactivating,
  authed,
  location,
  text,
  conditionsText,
  browserTitle,
  coupon,
  prepaidOfferId,
  heroTitle,
  heroSubtitle,
  resurrectionCampaign,
  isResurrectionCampaignLoading,
  featuredMeals
}) => {
  const auth0 = useAuth0()
  const [readyToRestart, setReadyToRestart] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [openExpiredModal, setOpenExpiredModal] = useState(false)
  const [isFromLogin, setIsFromLogin] = useState(false)

  const { campaignSlug } = useParams()
  const { chefBrandIds, mealIds } = parse(location.search.slice(1))
  const { flagsReady } = useFlagsStatus()
  const resurrectionFunnelVariant = useVariant(FF_RESURRECTION_FUNNEL)
  const isNewResurrectionExpEnabled =
    !campaignSlug &&
    !mealIds &&
    !chefBrandIds &&
    resurrectionFunnelVariant.name === 'TREATMENT'

  useEffect(() => {
    trackResurrectionLandingPage(
      'Page Loaded',
      'Resurrection Landing Page Started'
    )

    const { d, fromLogin } = parse(location.search.slice(1))

    setIsFromLogin(!!fromLogin)

    const isExpired = d
      ? moment().startOf('day') > moment(d).add(4, 'days')
      : false

    setIsExpired(isExpired)
    setOpenExpiredModal(isExpired)
    setReadyToRestart(fromLogin || false)

    if (!getCookie(RESURRECTION_PATH_COOKIE_NAME))
      setCookie(RESURRECTION_PATH_COOKIE_NAME, location.pathname, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!resurrectionCampaign && campaignSlug) {
      actions.getResurrectionCampaign({
        campaignSlug
      })
    }
  }, [campaignSlug, resurrectionCampaign, actions])

  useEffect(() => {
    if (
      !isFromLogin &&
      resurrectionCampaign &&
      Array.isArray(resurrectionCampaign.highlightedMealsIds) &&
      resurrectionCampaign.highlightedMealsIds.length > 0
    ) {
      actions.getFeaturedMeals({
        attrs: { mealIds: resurrectionCampaign.highlightedMealsIds },
        source: 'STATIC'
      })
    }
  }, [resurrectionCampaign, actions, isFromLogin])

  useEffect(() => {
    if (
      readyToRestart &&
      subscriptionInfo &&
      subscriptionInfo.status.toLowerCase() === 'inactive' &&
      !isResurrectionCampaignLoading &&
      flagsReady
    ) {
      setReadyToRestart(false)

      if (isNewResurrectionExpEnabled) {
        window.location.href =
          SIGNUP_URL + '/welcome-back' + (coupon ? `?coupon=${coupon}` : '')
        return
      }
      actions.reactivateSubscriptionInit({
        coupon,
        prepaidOfferId,
        resurrectionCampaign: {
          ...(resurrectionCampaign || {}),
          id:
            resurrectionCampaign?.id ||
            ((chefBrandIds || mealIds) && location.pathname.split('/').pop()),
          chefBrandIds: chefBrandIds?.split(',').map(n => parseInt(n)) || [],
          highlightedMealsIds: [
            ...(mealIds?.split(',').map(n => parseInt(n)) || []),
            ...(resurrectionCampaign?.highlightedMealsIds || [])
          ]
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actions,
    readyToRestart,
    subscriptionInfo,
    isResurrectionCampaignLoading,
    flagsReady,
    isNewResurrectionExpEnabled
  ])

  useEffect(() => {
    if (!auth0.isLoading && resurrectionFunnelVariant.enabled) {
      trackResurrectionFunnelExperiment(
        'Growth - Resurrection - Landing Page',
        resurrectionFunnelVariant.name
      )
    }
  }, [
    auth0.isLoading,
    isNewResurrectionExpEnabled,
    resurrectionFunnelVariant.enabled,
    resurrectionFunnelVariant.name
  ])

  const restartAction = () => {
    if (isFromLogin) {
      return
    }

    trackResurrectionButtonClicked(
      'Start Resurrection Flow',
      'Growth - Resurrection - Landing Page'
    )

    if (isNewResurrectionExpEnabled) {
      window.location.href =
        SIGNUP_URL + '/welcome-back' + (coupon ? `?coupon=${coupon}` : '')
      return
    }
    const { errorMessage, ...search } = parse(window.location.search, {
      ignoreQueryPrefix: true
    })

    if (isExpired) {
      this.toggleExpiredModal()
    } else {
      if (!authed) {
        auth0.loginWithRedirect({
          action: 'login',
          appState: {
            returnTo: `${window.location.pathname}?${stringify({
              ...search,
              fromLogin: true
            })} `
          },
          errorMessage
        })
      } else {
        setReadyToRestart(true)
      }
    }
  }

  const toggleExpiredModal = () => {
    setOpenExpiredModal(!openExpiredModal)
  }

  if (subscriptionInfo && subscriptionInfo.status.toLowerCase() === 'active') {
    return <Redirect to="/validate-cc" />
  }

  if (
    auth0.isLoading ||
    (auth0.isAuthenticated && !subscriptionInfo) ||
    readyToRestart ||
    isReactivating ||
    isResurrectionCampaignLoading
  ) {
    return <Loader />
  }

  return (
    <ResurrectionContainer>
      {isExpired && (
        <Modal isOpen={openExpiredModal} onRequestClose={toggleExpiredModal}>
          <ExpiredModal>
            <p>
              <h2>Sorry, this offer has ended</h2>
              If you would like to restart your orders contact your account
              executive at{' '}
              <a href="mailto:support@cookunity.com">
                support@cookunity.com
              </a>{' '}
              current promotions.
            </p>
          </ExpiredModal>
        </Modal>
      )}

      <Header restartAction={restartAction} />

      <ContentContainer>
        <DishBannerResurrection
          restartAction={restartAction}
          text={
            pathOr(null, ['heroPromotionCopy'], resurrectionCampaign) || text
          }
          browserTitle={browserTitle}
          heroTitle={
            pathOr(null, ['heroTitle'], resurrectionCampaign) || heroTitle
          }
          heroSubtitle={
            pathOr(null, ['heroSubtitle'], resurrectionCampaign) || heroSubtitle
          }
          heroImageUrl={pathOr(null, ['heroImageUrl'], resurrectionCampaign)}
        />

        {featuredMeals && (
          <MealsSliderContainer>
            <h2>
              {pathOr(null, ['menuSectionTitle'], resurrectionCampaign) ||
                'Recommended Meals'}
            </h2>
            <MealCardSlider>
              {Array.isArray(featuredMeals.data) &&
              featuredMeals.data.length > 0
                ? featuredMeals.data.map((meal, i) => (
                    <RegularMealCard
                      key={`${meal.id}-${i}`}
                      meal={{
                        ...meal,
                        imageUrl: meal.imageUrl || meal.image_url
                      }}
                      isEditable={false}
                      onMealClick={() => {}}
                      onChefClick={() => {}}
                    />
                  ))
                : ['', '', '', '', ''].map((_, i) => (
                    <RegularMealCard key={i} meal={{}} isLoading={true} />
                  ))}
            </MealCardSlider>
          </MealsSliderContainer>
        )}

        <InfoContainer>
          <Info
            title="New chefs. Local kitchens."
            text="We’re a collective of over 80 independent chefs and counting, now cooking in New York, LA, Atlanta, Austin, Chicago, Seattle and Miami."
            image={Section1}
            reverse
          />

          <Info
            title="Exceptional meals."
            text="We've added more meals, more plant-based alternatives, and more
          premium ingredients to our menu. From truffle mac and cheese to
          charred octopus, you'll enjoy an incredible variety of global
          dishes you won't find anywhere else."
            image={Section2}
            action={
              <Link href="https://www.cookunity.com/our-menu" target="_new">
                View our menu
                <CuiIcon name="arrowRight" />
              </Link>
            }
          />

          <Info
            title="Biodegradable packaging."
            text="We use industry-leading compostable trays and 100% recyclable
          outer sleeves. We also redesigned our packaging to keep meals
          fresher and increase shelf-life for up to 4-6 days."
            image={Section3}
            reverse
          />
        </InfoContainer>

        <Footer
          restartAction={restartAction}
          text={
            pathOr(null, ['heroPromotionCopy'], resurrectionCampaign) || text
          }
          conditionsText={
            pathOr(null, ['conditionsCopy'], resurrectionCampaign) ||
            conditionsText
          }
        />
      </ContentContainer>
    </ResurrectionContainer>
  )
}

export default ResurrectionPage
