import moment from 'moment'
import momentTZ from 'moment-timezone'
import { getCarts } from 'src/redux-api-bridge/order/selectors'

import { getMealsCount } from '../../redux-api-bridge/order/utils'
import { getTimeLeft } from '../DeliveryDate/utils'
import { getTimeslot } from '../../utils/utils'
import {
  getCurrentTimeslot,
  isFlexPlanSelector
} from '../../redux-api-bridge/selectors'
import { getAddresses } from '../../modules/MyDeliveries/selectors'
import { getUpcomingDeliveries } from '../../modules/MyDeliveries/selectors'
import { getAddress, getCartByDate } from 'src/modules/MyDeliveries/utils'
import { COPY_STATUS, ORDER_STATUS, FLEX_MODE_MEALS_BY_PLAN } from './constants'

export const dayByDeliveryDateSelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return
  }
  return state.api?.subscription?.upcomingDays?.find(
    item => item.displayDate === deliveryDate
  )
}

export const planMealsQtySelector = state => {
  return state.api?.subscription?.userPlan?.mealsPerDelivery
}

// Taken from src/modules/MyDeliveries/components/DeliveriesList/index.jsx > getMealsSelectedCount
// TODO refactor when understand
const getMealsSelectedCount = ({ cart, order, recommendation }) => {
  if (order) return getMealsCount(order.items, 'product')
  if (recommendation) return getMealsCount(recommendation.meals)
  if (cart && cart.length) return getMealsCount(cart)
  return 0
}

export const mealsSelectedCountSelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return
  }
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  if (!day) {
    return
  }
  return getMealsSelectedCount({
    cart: getCartByDate(getCarts(state), day.date),
    order: day.order,
    recommendation: day.recommendation
  })
}

export const originSelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return false
  }
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  if (!day) {
    return false
  }

  return day?.order?.allowEditAfterCutoff
}

// Lets keep copy status by high level status, for exceptions like recommendation
// we can use conditionals on every specific place. Eg. "subtitleCopySelector" function switch case.
// Otherwise to check if an order is fullfilled, either by user or recommendation, we would need to check
// more than one condition.
export const copyStatusSelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return ''
  }
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  if (!day) {
    return
  }
  const mealsCount = mealsSelectedCountSelector(state, deliveryDate)
  const mealsPerDelivery = state.api?.subscription?.userPlan?.mealsPerDelivery

  const orderStatus = orderStatusSelector(state, deliveryDate)

  if (day.holidayMessage) {
    return COPY_STATUS.HOLIDAY
  }
  if (!day.menuAvailable) {
    return COPY_STATUS.NO_MENU
  }
  if (day.skip) {
    return COPY_STATUS.ORDER_SKIPPED
  }
  // day.date is the shipping date
  // day.displayDate is the delivery date
  const isShippingDay = moment().isSameOrAfter(day.date)
  if (isShippingDay) {
    return COPY_STATUS.ORDER_IN_SHIPPING_DAY
  }
  if (orderStatus === ORDER_STATUS.PENDING) {
    return COPY_STATUS.ORDER_REQUEST_PENDING
  }
  if (day.order && (!day.canEdit || day.order.allowEditAfterCutoff)) {
    return COPY_STATUS.ORDER_CONFIRMED
  }
  if (day.order && day.canEdit) {
    return COPY_STATUS.ORDER_REQUESTED
  }
  if (mealsCount === 0) {
    return COPY_STATUS.EMPTY_ORDER
  }
  if (mealsCount >= mealsPerDelivery && !day.order) {
    return COPY_STATUS.ORDER_FULLFILLED_BUT_NOT_REQUESTED
  }
  if (mealsCount > 0 && mealsCount < mealsPerDelivery) {
    return COPY_STATUS.PARTIAL_ORDER
  }
}

export const menuAvailableFromCopySelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return ''
  }
  const deliveryDay = dayByDeliveryDateSelector(state, deliveryDate)
  return moment(deliveryDay.menuAvailableFrom).format('MMM DD')
}

export const subtitleCopySelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return ''
  }
  const deliveryDay = dayByDeliveryDateSelector(state, deliveryDate)
  const copyStatus = copyStatusSelector(state, deliveryDate)
  const timeLeftUntilCutoffLabel = timeLeftUntilCutoffLabelSelector(
    state,
    deliveryDate
  )
  switch (copyStatus) {
    case COPY_STATUS.HOLIDAY:
      return 'Holiday - no delivery'
    case COPY_STATUS.NO_MENU:
      const label = moment(deliveryDay.menuAvailableFrom).format('MMM DD')
      return `Menu available on ${label}`
    case COPY_STATUS.ORDER_SKIPPED:
      return 'You’ve skipped this order'
    case COPY_STATUS.PARTIAL_ORDER:
    case COPY_STATUS.EMPTY_ORDER:
      return `${timeLeftUntilCutoffLabel} left to choose meals`
    case COPY_STATUS.ORDER_FULLFILLED_BUT_NOT_REQUESTED:
      if (deliveryDay.recommendation) {
        return 'Order placed using recommendations'
      }
      return 'Confirm your order'
    case COPY_STATUS.ORDER_REQUESTED:
      return 'All set?'
    case COPY_STATUS.ORDER_REQUEST_PENDING:
      return 'Retrieving your order info…'
    case COPY_STATUS.ORDER_IN_SHIPPING_DAY:
      function fromNowFormat() {
        return `[${moment(deliveryDate).fromNow()}]`
      }
      const eta = moment(deliveryDate).calendar(null, {
        sameDay: '[today]',
        nextDay: '[tomorrow]',
        nextWeek: fromNowFormat,
        sameElse: fromNowFormat
      })
      return `Arriving ${eta}`
    case COPY_STATUS.ORDER_CONFIRMED:
      return 'We’ve received your order'
    default:
      return ''
  }
}

export const hintCopySelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return ''
  }
  const copyStatus = copyStatusSelector(state, deliveryDate)
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  switch (copyStatus) {
    case COPY_STATUS.HOLIDAY:
      return 'Please reschedule delivery for this order.'
    case COPY_STATUS.NO_MENU:
      return 'We’ll let you know when it’s ready.'
    case COPY_STATUS.ORDER_SKIPPED:
      const cutoffMoment = cutoffMomentSelector(state, deliveryDate)
      return `You can still unskip before ${cutoffMoment.format('ddd[.] H a')}`
    case COPY_STATUS.EMPTY_ORDER:
      if (isFlexPlanSelector(state)) {
        return `Select at least ${FLEX_MODE_MEALS_BY_PLAN} meals to complete your order.`
      }
      return 'Select your own or leave it to us.'
    case COPY_STATUS.PARTIAL_ORDER:
      const mealsCount = mealsSelectedCountSelector(state, deliveryDate)
      const mealsPerDelivery =
        state.api?.subscription?.userPlan?.mealsPerDelivery
      const missingMeals = mealsPerDelivery - mealsCount
      return `Select ${missingMeals} more meals to complete your order.`
    case COPY_STATUS.ORDER_FULLFILLED_BUT_NOT_REQUESTED:
      if (day.recommendation) {
        const label = moment(day.cutoff.time).format('ddd. DD')
        return `You can edit your order before ${label}`
      }
      return 'Review and confirm your order to lock in delivery.'
    case COPY_STATUS.ORDER_REQUESTED:
      return 'There´s still time to change meals or add extras.'
    case COPY_STATUS.ORDER_REQUEST_PENDING:
      return "Can't wait? Try refreshing your screen."
    case COPY_STATUS.ORDER_IN_SHIPPING_DAY:
      const start = moment(day.order.time_start, 'HH').format('h a')
      const end = moment(day.order.time_end, 'HH').format('h a')
      return `By ${start} - ${end}`
    case COPY_STATUS.ORDER_CONFIRMED:
      return 'We’ll let you know when it’s on the way.'
    default:
      return ''
  }
}

const timeLeftUntilCutoffLabelSelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return ''
  }
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  const left = getTimeLeft(day.cutoff)
  return left
}

export const cutoffMomentSelector = (state, deliveryDate) => {
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  return momentTZ(day.cutoff.time).tz(day.cutoff.userTimeZone)
}

export const orderStatusSelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return
  }
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  if (!day) {
    return
  }

  if (!day.canEdit || day?.order?.allowEditAfterCutoff) {
    return ORDER_STATUS.CONFIRMED
  }
  if (day.skip) {
    return ORDER_STATUS.SKIPPED
  }
  if (day.isPaused) {
    return ORDER_STATUS.PAUSED
  }
  if (day.order?.id === '0') {
    return ORDER_STATUS.PENDING
  }
  if (day.order) {
    return ORDER_STATUS.REQUESTED
  }
}

export const isOrderSkippedSelector = (state, deliveryDate) => {
  const orderStatus = orderStatusSelector(state, deliveryDate)
  return orderStatus === ORDER_STATUS.SKIPPED
}

export const isOrderConfirmedSelector = (state, deliveryDate) => {
  const orderStatus = orderStatusSelector(state, deliveryDate)
  return orderStatus === ORDER_STATUS.CONFIRMED
}

export const deliveryAddressLabelSelector = (state, deliveryDate: string) => {
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  if (!day) {
    return '-'
  }
  /** 
  @type { {
    id:504859
    city:"New York"
    country:null
    region:"New York"
    regionId:null
    postcode:"10001"
    telephone:"2222222222"
    street:"209 West 29th Street"
  } }
   */
  const address = day.order?.address
  if (address) {
    return getAddress(day.order.address)
  }
  // Default address
  return getAddresses(state)
}

// Legacy, required to show reschedule panel.
// Code taken from src/modules/MyDeliveries/components/DeliveriesList/index.jsx#L231
export const timeslotSelector = (state, deliveryDate) => {
  if (!deliveryDate) {
    return
  }
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  if (!day) {
    return
  }
  if (day.order) {
    return getTimeslot({
      start: day.order.time_start,
      end: day.order.time_end
    })
  } else {
    return getCurrentTimeslot(state)
  }
}

export const showRecommendedTagSelector = (state, deliveryDate) => {
  const day = dayByDeliveryDateSelector(state, deliveryDate)
  if (!day) {
    return false
  }
  const { recommendation, order, holidayMessage } = day
  return !holidayMessage && !order && recommendation
}

export const canSkipDay = (state, deliveryDate) => {
  if (!deliveryDate) {
    return false
  }
  const days = getUpcomingDeliveries(state)
  const day = days.find(item => item.displayDate === deliveryDate)
  return day?.menuAvailable && !day?.isFirstOrder
}
