import { handleActions } from 'redux-actions'

import * as accountActions from './actions'

const initialState = {
  changePassword: {
    loading: false,
    success: false,
    error: false
  },
  deleteAccount: {
    loading: false,
    success: false,
    error: false
  }
}

export default handleActions(
  {
    [accountActions.changePasswordStart]: state => {
      return {
        ...state,
        changePassword: {
          loading: true,
          success: false,
          error: false
        }
      }
    },
    [accountActions.changePasswordSuccess]: state => {
      return {
        ...state,
        changePassword: {
          loading: false,
          success: true,
          error: false
        }
      }
    },
    [accountActions.changePasswordError]: state => {
      return {
        ...state,
        changePassword: {
          loading: false,
          success: false,
          error: true
        }
      }
    },
    [accountActions.deleteAccountStart]: state => {
      return {
        ...state,
        deleteAccount: {
          loading: true,
          success: false,
          error: false
        }
      }
    },
    [accountActions.deleteAccountSuccess]: state => {
      return {
        ...state,
        deleteAccount: {
          loading: false,
          success: true,
          error: false
        }
      }
    },
    [accountActions.deleteAccountError]: state => {
      return {
        ...state,
        deleteAccount: {
          loading: false,
          success: false,
          error: true
        }
      }
    }
  },
  initialState
)
