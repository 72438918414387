import '../App.scss'
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css'
import 'front-lib/dist/index.css'

import * as kustomer from '../utils/kustomer'

import React, { useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import MetaTags from '../components/MetaTags'
import { Routes } from '../components/Routes/Routes'
import ScrollToTop from '../common/ScrollToTop'
import { Toaster } from '../components/Toaster/Toaster'
import UnleashProvider from '../components/UnleashProvider'
import { setDatadogUser } from 'src/utils/datadog.js'
import { cio_identify } from '../utils/customer'
import { getKustomerToken } from '../api'
import { getUserMenuByDate } from '../redux-api-bridge/menu/effects'
import { initApiStore } from '../redux-api-bridge'
import { limitNavegation } from '../routes/utils'
import routes from '../routes'
import { selectSubscriptionStatusEvent } from '../redux-api-bridge/selectors'
import tracking from '../shared/tracking'
import { useAuth0 } from '@auth0/auth0-react'
import { useIsMobile } from 'src/hooks/useIsMobile'
import Global from 'src/componentsV2/Global/Global'
import { pixel } from 'src/utils/pixel'
import { useIdentifyAccountData } from 'src/hooks/useIdentifyAccountData'

const loadLibraries = ({ email } = {}) => {
  kustomer.getTokenAndInit(getKustomerToken, { email })
}

const identifyUser = ({ magento_id, subscription_id, email } = {}) => {
  if (email && subscription_id && magento_id) {
    tracking.identify(magento_id, { email, subscription_id })
    tracking.track('Login', {
      email
    })
    setDatadogUser(magento_id, subscription_id, email)
    cio_identify(email)
  }
}

export function App() {
  const { isAuthenticated: authed, error, user, isLoading } = useAuth0()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const eventSubscription = useSelector(selectSubscriptionStatusEvent)
  const isMobile = useIsMobile()
  useIdentifyAccountData()

  useEffect(() => {
    if (authed) {
      loadLibraries(user)
      identifyUser(user)
      prefetchMenu(dispatch)
      dispatch(initApiStore())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed, user])

  useEffect(() => {
    limitNavegation(history, eventSubscription, location, routes)
  }, [history, eventSubscription, location])

  useEffect(() => {
    if (isMobile) {
      kustomer.hideChat()
    }
  }, [isMobile])

  useEffect(() => {
    pixel.init()
  }, [])

  if (isLoading) {
    return null
  }

  if (error) {
    const errorMessage = error.message.replace(/\s+/g, '_')
    return <Redirect to={`/logout?errorMessage=${errorMessage}`} />
  }

  function prefetchMenu(dispatch) {
    const currentUrl = window.location.href
    const menuRegex = /\/meals\/?\?date=\d{4}-\d{2}-\d{2}/
    if (menuRegex.test(currentUrl)) {
      const urlSearchParams = new URLSearchParams(currentUrl.split('?')[1])
      const dateParam = urlSearchParams.get('date')
      const isAfterCutoff = urlSearchParams.get('afterCutoff') === 'true'
      getUserMenuByDate(dateParam, true, isAfterCutoff)(dispatch)
    }
  }

  return (
    <div className="app-routes-container">
      <MetaTags />
      <Toaster />
      <ScrollToTop>
        <UnleashProvider>
          <Routes />
          <Global />
        </UnleashProvider>
      </ScrollToTop>
    </div>
  )
}
