export const formatOrders = orders => {
  let formatedOrders = []

  if (orders) {
    formatedOrders = orders.reduce((accum, elem) => {
      if (elem) {
        return [
          ...accum,
          {
            deliveryDate: elem.order.delivery_date,
            end: elem.order.time_end,
            start: elem.order.time_start,
            tip: elem.order.tip,
            products: elem.order.items.map(item => ({
              id: Number(item.product.id),
              qty: item.qty
            }))
          }
        ]
      }
      return accum
    }, [])
  }
  return formatedOrders
}

export const formatDeliveryTimeOptions = deliveryTimeOptions => {
  return deliveryTimeOptions && deliveryTimeOptions.length
    ? deliveryTimeOptions.map(elem => ({ ...elem, value: elem.label }))
    : []
}
