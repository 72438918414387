import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getAllCartsSelector = state => pathOr([], ['cart', 'carts'], state)

export const cartByDateSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts ? carts[date] : null
  })

export const productsWithStockInCartSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts?.[date]?.filter(p => p.stock > 0) ?? []
  })

export const productsWithoutStockInCartSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts?.[date]?.filter(p => p.stock <= 0) ?? []
  })

export const isCartOpenSelector = state =>
  pathOr(false, ['cart', 'isOpen'], state)

export const getCartErroredSelector = state =>
  pathOr('', ['cart', 'isError'], state)
