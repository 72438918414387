import { handleActions } from 'redux-actions'
import { uniqWith } from 'ramda'

import * as menuActions from '../menu/actions'
import { getUpcomingDays } from '../actions'

import * as orderActions from './actions'
import { calculateMealCategory, mealsAreEquals } from 'src/utils/utils'
import { getCartFromMenuCategories } from '../cart/utils'

const initialState = {
  active: null,
  data: {}
}

const orderInitialState = {
  confirmedItems: [],
  items: [],
  editingOrder: false,
  orderDetail: {
    qtyPlanMeals: 0,
    totalPlanPrice: 0.0,
    totalExtraMeals: 0.0,
    totalTaxes: 0.0,
    totalDeliveryFee: 0.0,
    subTotalOrder: 0.0,
    totalPromoDiscount: 0,
    totalOrder: 0
  }
}

const getOrder = (state, date) => state.data[date] || { ...orderInitialState }

const getActiveOrder = state => getOrder(state, state.active)

const updateOrder = (state, date, order) => {
  return {
    ...state,
    data: {
      ...state.data,
      [date]: {
        ...state.data[date],
        ...order
      }
    }
  }
}

const updateActiveOrder = (state, order) =>
  updateOrder(state, state.active, order)

export default handleActions(
  {
    [orderActions.syncProducts]: (state, action) => {
      const { menuMeals, cartProducts } = action.payload
      const cartProductIds = cartProducts.map(
        _product => _product.entity_id || _product.inventoryId
      )
      const meals = menuMeals
        .filter(_meal => cartProductIds.includes(_meal.id))
        .map(_meal => {
          const product = cartProducts.find(_product =>
            mealsAreEquals(_product, _meal)
          )
          return {
            ..._meal,
            qty: product.qty,
            quantity: product.qty
          }
        })
      const uniqueMeals = uniqWith(mealsAreEquals, meals)
      return updateActiveOrder(state, {
        editingOrder: true,
        items: [...uniqueMeals]
      })
    },
    [orderActions.addProduct]: (state, action) => {
      const { items } = getActiveOrder(state)
      const { product, quantity } = action.payload

      const itemIndex = items.findIndex(p => mealsAreEquals(product, p))

      if (itemIndex === -1) {
        return updateActiveOrder(state, {
          editingOrder: true,
          items: [...items, { ...product, qty: quantity }]
        })
      }

      return updateActiveOrder(state, {
        editingOrder: true,
        items: [
          ...items.slice(0, itemIndex),
          { ...items[itemIndex], qty: items[itemIndex].qty + quantity },
          ...items.slice(itemIndex + 1)
        ]
      })
    },
    [orderActions.removeProduct]: (state, { payload }) => {
      const { items } = getActiveOrder(state)
      const { product, quantity } = payload

      const found = items.find(p => mealsAreEquals(p, product))

      if (found) {
        if (quantity >= found.qty) {
          return updateActiveOrder(state, {
            editingOrder: true,
            items: items.filter(p => !mealsAreEquals(p, product))
          })
        } else {
          return updateActiveOrder(state, {
            editingOrder: true,
            items: items.map(p => {
              if (mealsAreEquals(p, product)) {
                return {
                  ...p,
                  qty: p.qty - quantity
                }
              }
              return p
            })
          })
        }
      }

      return state
    },
    [menuActions.resetUserMenu]: () => initialState,
    [orderActions.clearOrder]: state =>
      updateActiveOrder(state, { items: [], editingOrder: true }),
    [orderActions.discardOrderChanges]: state =>
      updateActiveOrder(state, {
        items: getActiveOrder(state).confirmedItems,
        editingOrder: false
      }),
    [orderActions.getOrderDetail]: (state, { payload }) => {
      return updateActiveOrder(state, {
        orderDetail: payload
      })
    },
    [getUpcomingDays.succeed.type]: (state, { payload }) => {
      const { upcomingDays } = payload
      state = {
        ...state,
        data: {}
      }
      if (state.active) {
        state.data[state.active] = orderInitialState
      }

      upcomingDays.forEach(upcomingDay => {
        const cart = upcomingDay.cart
        const orderDate = upcomingDay.date
        if (upcomingDay.order?.items?.length > 0) {
          const items = upcomingDay.order.items.map(item => {
            return {
              ...item.product,
              categoryId: calculateMealCategory(item.product),
              category_id: calculateMealCategory(item.product),
              entity_id: +item.product.id,
              qty: item.qty
            }
          })
          state = updateOrder(state, orderDate, {
            ...orderInitialState,
            confirmedItems: items,
            items
          })
        } else if (!!upcomingDay.recommendation) {
          const items = upcomingDay.recommendation.meals?.map(item => {
            return {
              ...item,
              categoryId: calculateMealCategory(item),
              category_id: calculateMealCategory(item),
              entity_id: +item.id,
              qty: item.qty
            }
          })
          state = updateOrder(state, orderDate, {
            ...orderInitialState,
            confirmedItems: items,
            items
          })
        } else if (cart.length > 0) {
          const items = cart.map(item => {
            return {
              ...item.product,
              categoryId: calculateMealCategory(item.product),
              category_id: calculateMealCategory(item.product),
              entity_id: +item.product.id,
              qty: item.qty,
              quantity: item.qty
            }
          })
          state = updateOrder(state, orderDate, {
            ...orderInitialState,
            items
          })
        }
      })

      return state
    },
    [menuActions.getUserMenuSuccess]: (state, action) => {
      const {
        menu: { categories },
        date: orderDate
      } = action.payload

      const order = getOrder(state, orderDate)

      if (order.confirmedItems?.length) {
        order.items = [...order.confirmedItems]
      } else if (order.items?.length > 0) {
        order.items = getCartFromMenuCategories(categories, order.items)
      }

      order.items = uniqWith(mealsAreEquals, order.items || [])
      order.confirmedItems = uniqWith(
        mealsAreEquals,
        order.confirmedItems || []
      )

      return {
        ...updateOrder(state, orderDate, order),
        active: orderDate
      }
    },
    [orderActions.setActiveOrder]: (state, action) => {
      const { date } = action.payload
      return { ...state, active: date }
    }
  },
  initialState
)
