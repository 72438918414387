import './Header.scss'
import { useEmbedded } from '../../embedded'

import HeaderDesktop from './components/header/HeaderDesktop'
import HeaderMobile from './components/header/HeaderMobile'
import UserMenu from './components/userMenu/UserMenu'
import { useHeader } from './useHeader'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { useIsIntersecting } from 'src/hooks/useIsIntersecting'

const Header = props => {
  const { isMenuOpen, handleCloseMenu, ...headerProps } = useHeader()
  const { isAppEmbedded } = useEmbedded()
  const isMobile = useIsMobile()
  const HeaderComponent = isMobile ? HeaderMobile : HeaderDesktop

  // if not onHeaderVisibilityChange callback provided, intersection wont work
  const { ref } = useIsIntersecting(
    '-5px 0px 0px 0px',
    props?.onHeaderVisibilityChange
  )

  if (isAppEmbedded) {
    return null
  }

  return (
    <>
      <div ref={ref}>
        <HeaderComponent {...headerProps} />
        <UserMenu isMenuOpen={isMenuOpen} handleCloseMenu={handleCloseMenu} />
      </div>
    </>
  )
}

export default Header
