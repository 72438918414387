import { useRef, useState, useEffect } from 'react'

export function useIsIntersecting(
  rootMargin = '0px 0px 0px 0px',
  onIntersectCallback
) {
  const ref = useRef(null)

  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (!onIntersectCallback) {
      return
    }

    const options = {
      root: null,
      rootMargin: rootMargin,
      threshold: 0
    }

    const onIntersectionCallback = entries => {
      const [entry] = entries
      setIsIntersecting(entry.isIntersecting)
      onIntersectCallback(entry.isIntersecting)
    }

    const observer = new IntersectionObserver(onIntersectionCallback, options)

    observer.observe(ref.current)

    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return { ref, isIntersecting }
}
