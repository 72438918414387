import { createSelector } from 'reselect'
import moment from 'moment'
import { pathOr } from 'ramda'
import { DAY_NAMES } from './components/constants'

const pausePlanIsUpdating = state =>
  pathOr(false, ['api', 'pausePlanStatus', 'inFlight'], state)

export const getSubscriptionPlan = state =>
  pathOr(null, ['api', 'subscription', 'userPlan'], state)

const getUserDeliveryDays = state =>
  pathOr(null, ['api', 'subscription', 'info', 'deliveryDays'], state)

export const getAvailableDeliveryDays = state =>
  pathOr([], ['api', 'subscription', 'info', 'availableDeliveryDays'], state)

export const getPlanId = state =>
  pathOr(0, ['api', 'subscription', 'info', 'plan_id'], state)

const getAvailableTimeslots = state =>
  pathOr([], ['api', 'subscription', 'timeslots'], state)

const userDeliveryInfoIsUpdating = state =>
  pathOr(false, ['api', 'updateSubscriptionDaysStatus', 'inFlight'], state)

const planUpdateIsLoading = state =>
  pathOr(false, ['api', 'setSubscriptionPlanStatus', 'inFlight'], state)

export const getIdleDeliveryDays = state =>
  pathOr(null, ['api', 'idleDeliveryDays'], state)

const userDeliveryInfoIsLoading = createSelector(
  [getUserDeliveryDays, userDeliveryInfoIsUpdating],
  (deliveryDays, isUpdating) => !deliveryDays || isUpdating
)

export const getTimeslots = createSelector([getAvailableTimeslots], timeslots =>
  timeslots.map(t => ({
    ...t,
    value: t.label
  }))
)

export const getUserDeliveryInfoSelector = createSelector(
  [getUserDeliveryDays, getAvailableDeliveryDays],
  (deliveryDays, availableDeliveryDays) => {
    if (deliveryDays) {
      const day = deliveryDays.length > 0 ? deliveryDays[0] : null
      const start = day && moment(day.time_start, 'HH:mm').format('h:mm A')
      const end = day && moment(day.time_end, 'HH:mm').format('h:mm A')
      return {
        selectedDeliveryDays: deliveryDays.map(delivery =>
          availableDeliveryDays.find(day => day.day === delivery.day)
        ),
        selectedTimeslot: {
          label: day ? `${start} - ${end}` : '',
          value: day ? `${start} - ${end}` : '',
          start: day ? day.time_start : '',
          end: day ? day.time_end : ''
        }
      }
    }
    return null
  }
)

export const getDeliveryDay = createSelector(
  [getUserDeliveryDays, getAvailableDeliveryDays],
  (deliveryDays, availableDeliveryDays) => {
    if (!deliveryDays || !availableDeliveryDays) return null

    const day = availableDeliveryDays.find(
      availableDay => availableDay.day === deliveryDays[0].day
    )
    if (day) {
      return `${moment(day.day, 'd').format('dddd')}s`
    }

    return null
  }
)

export const idleDaysIsLoading = createSelector(
  [getIdleDeliveryDays],
  idleDays => {
    return !idleDays || idleDays.inFlight
  }
)

export const getIdleDays = createSelector([getIdleDeliveryDays], idleDays => {
  if (idleDays) {
    const convertedIdleDays = idleDays.availableDays?.map(
      index => DAY_NAMES[index]
    )
    return convertedIdleDays
  }
  return null
})

export const planIsLoading = createSelector(
  [
    pausePlanIsUpdating,
    getSubscriptionPlan,
    userDeliveryInfoIsLoading,
    planUpdateIsLoading
  ],
  (pausePlan, getSubscriptionPlan, deliveryInfoLoading, planIsUpdating) =>
    pausePlan || !getSubscriptionPlan || deliveryInfoLoading || planIsUpdating
)

export const getUpdatePlanStatus = state => {
  return state?.api?.setSubscriptionPlanStatus || {}
}
