export const MEAL_CARD_PRICE_EXPERIMENT = 'meal-card-price-experiment'
export const NEW_PICTURES_SEATTLE_EXPERIMENT = 'new-pictures-seattle-experiment'

export const FIVE_MILESTONE_CHALLENGE_EXPERIMENT = {
  name: 'FIVE_MILESTONE_CHALLENGE',
  experiment: {
    id: 'gtt-4143',
    name: 'Growth - Activation - 5 Milestones Challenge'
  },
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const THREE_ORDERS_CHALLENGE_EXPERIMENT = {
  name: 'THREE_ORDERS_IN_THIRTY_DAYS',
  experiment: {
    id: 'gro-sc-4762',
    name: 'Growth - Activation - 3 Orders Challenge'
  },
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const UNSKIP_WHEN_IS_PAUSED = {
  name: 'UNSKIP_WHEN_IS_PAUSED',
  experiment: {
    id: 'gtt-4676',
    name: 'Growth - Activation - Unskip when Paused'
  },
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const ADD_AND_SAVE_BANNER = {
  name: 'ADD_AND_SAVE_BANNER',
  experiment: {
    id: 'gtt-5038',
    name: 'Growth - Activation - Add&Save Ribbon'
  },
  variantsMap: {
    disabled: 'disabled',
    control: 'control',
    treatment1: 'treatment-1'
  }
}
