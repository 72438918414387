import './DropdownSelector.scss'
import { FormItemHeader } from '../FormItemHeader/FormItemHeader'
import Select, { components } from 'react-select'
import OpenIndicator from 'src/assets/icons/dropdown-arrow-down.png'
import CloseIndicator from 'src/assets/icons/dropdown-arrow-up.png'
import Close from 'src/assets/icons/xclose.png'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import { useEffect, useState } from 'react'
import { CuiBottomSheet, CuiInput, CuiCheckbox } from 'front-lib'
import { OptionItem } from 'src/types/preferences'

const DropdownSelector = ({
  title,
  info,
  subtitle,
  items,
  disabled,
  selected,
  noPreferenceSelected,
  onChange,
  onNoPreferenceSelection,
  name,
  withSearchInput,
  onInputClicked
}) => {
  const isMobile = useMobileDevice()
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false)
  const [filterInput, setFilterInput] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<OptionItem[]>([])
  const [options, setOptions] = useState<OptionItem[]>([])
  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>([])
  const [notSelectedOptions, setNotSelectedOptions] = useState<OptionItem[]>([])

  const mapOptions = option => ({
    ...option,
    value: option.id,
    name: option.label
  })

  const mapSelectedOptions = element => {
    const options = items?.map(mapOptions)
    const item = options?.find(e => e.id === element.id) || {}
    return { ...item, ...element }
  }

  useEffect(() => {
    if (filterInput) {
      const filtered = notSelectedOptions.filter(o =>
        o.label.toLowerCase().includes(filterInput.toLowerCase())
      )
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions(notSelectedOptions)
    }
  }, [filterInput, notSelectedOptions])

  useEffect(() => {
    const selectedOptions = selected?.map(mapSelectedOptions)
    const options = items?.map(mapOptions)
    const notSelectedOptions = options?.filter(
      option => !selectedOptions?.find(o => o.id === option.id)
    )
    setSelectedOptions(selectedOptions)
    setOptions(options)
    setNotSelectedOptions(notSelectedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selected])

  const IndicatorSeparator = props => null
  const MultiValueRemove = props => null
  const ClearIndicator = props => null

  const DropdownIndicator = props => {
    const {
      selectProps: { menuIsOpen }
    } = props
    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <img alt="close-dropdown" src={CloseIndicator} />
        ) : (
          <img alt="open-dropdown" src={OpenIndicator} />
        )}
      </components.DropdownIndicator>
    )
  }

  const MultiValueContainer = ({ selectProps, data }) => {
    const values = selectProps.value
    return !!options?.find(o => o.id === data.id) ? (
      <span className="preferences-dropdown__multivalue-list-item">
        {values[values.length - 1].label === data.label
          ? data.label
          : data.label + ', '}
      </span>
    ) : null
  }

  const MultiValueLabel = ({ children, innerProps }) => (
    <div className="preferences-dropdown__multivalue-label" {...innerProps}>
      <div>{children}</div>
    </div>
  )

  const CustomMenu = ({ children, innerProps }) => {
    if (isMobile) {
      return <></>
    }
    return (
      <div className="preferences-dropdown__input" {...innerProps}>
        {!!selectedOptions && selectedOptions.length > 0 && (
          <div className="preferences-dropdown__input-selected-list">
            {selectedOptions?.map(option =>
              !!options?.find(o => o.id === option.id) ? (
                <div
                  className="preferences-dropdown__input-selected-list-item"
                  role="button"
                  key={option.id}
                  onClick={() =>
                    onChange(
                      selectedOptions?.filter(o => o.id !== option.id),
                      name
                    )
                  }>
                  {option.label}
                  <img src={Close} alt="remove" />
                </div>
              ) : null
            )}
          </div>
        )}
        {children}
      </div>
    )
  }

  return (
    <div className="preferences-dropdown">
      <FormItemHeader title={title} subtitle={subtitle} info={info} />
      <div className="preferences-dropdown__selector-check">
        <div
          className="preferences-dropdown__list"
          onClick={e => {
            if (disabled) return
            if (isMobile) {
              e.preventDefault()
              e.stopPropagation()
              setBottomSheetOpen(bottomSheetOpen => !bottomSheetOpen)
            }
          }}>
          <Select
            classNamePrefix="preferences-dropdown-select"
            options={options}
            key={isMobile ? selected?.length : `select-${name}`}
            components={{
              Menu: CustomMenu,
              DropdownIndicator,
              IndicatorSeparator,
              ClearIndicator,
              MultiValueLabel,
              MultiValueContainer,
              MultiValueRemove
            }}
            isSearchable={withSearchInput}
            isMulti={true}
            placeholder={'Select any that apply'}
            value={selected?.map(mapSelectedOptions)}
            onChange={selection => onChange(selection, name)}
            closeMenuOnSelect={false}
            isDisabled={disabled}
            onMenuOpen={() => {
              if (disabled) return
              onInputClicked(title)
              isMobile &&
                setBottomSheetOpen(bottomSheetOpen => !bottomSheetOpen)
            }}
          />
        </div>
        <CuiCheckbox
          name={'allergies-no-selection'}
          id="allergies-no-selection"
          checked={noPreferenceSelected}
          type="checkbox"
          disabled={disabled}
          onChange={() => {
            onNoPreferenceSelection(name, !noPreferenceSelected)
          }}>
          <span
            onClick={() => {
              onNoPreferenceSelection(name, !noPreferenceSelected)
            }}>
            No preference
          </span>
        </CuiCheckbox>
      </div>
      <CuiBottomSheet isOpen={bottomSheetOpen}>
        <CuiBottomSheet.Header
          onRequestClose={() => setBottomSheetOpen(false)}
          className="preferences-bottom-sheet__header"></CuiBottomSheet.Header>
        <CuiBottomSheet.Body className="preferences-bottom-sheet__body">
          <FormItemHeader title={title} subtitle={subtitle} info={info} />
          {withSearchInput && (
            <CuiInput
              color="white"
              className={`form-input`}
              type="text"
              value={filterInput}
              onChange={e => setFilterInput(e.target.value)}
              placeholder=""
            />
          )}
          <div>
            {!!selectedOptions && selectedOptions.length > 0 && (
              <div className="preferences-dropdown__input-selected-list">
                {selectedOptions?.map(option => (
                  <div
                    className="preferences-dropdown__input-selected-list-item"
                    key={option.id}
                    role="button"
                    onClick={() => {
                      onChange(
                        selectedOptions?.filter(o => o.id !== option.id),
                        name
                      )
                    }}>
                    {option.label}
                    <img src={Close} alt="remove" />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="preferences-bottom-sheet__list">
            {filteredOptions?.map((option, index) => (
              <div
                key={`${option.id}-${index}`}
                role="button"
                className="preferences-bottom-sheet__list-item"
                onClick={e => {
                  onChange([...selectedOptions, option], name)
                }}>
                {option.label}
              </div>
            ))}
          </div>
        </CuiBottomSheet.Body>
      </CuiBottomSheet>
    </div>
  )
}

export { DropdownSelector }
