import { createSelector } from 'reselect'
import { pathOr } from 'ramda'
import moment from 'moment'

export const getAccountData = state =>
  pathOr({}, ['api', 'subscription', 'info'], state)

export const changePasswordState = state =>
  pathOr(null, ['account', 'changePassword'], state)

export const changePasswordStatus = createSelector(
  changePasswordState,
  status => status
)

export const getSignedUpDate = createSelector(getAccountData, account => {
  const createdAt =
    (account.createdAt && account.createdAt.substr(4, 11)) || null
  if (moment(createdAt).isValid())
    return moment(createdAt, 'MMM DD YYYY').format('YYYY-MM-DD')
  return null
})

export const getUserStore = state => {
  let store = null
  const stores = state.api.stores
  const storeId = pathOr(
    null,
    ['api', 'subscription', 'info', 'store_id'],
    state
  )

  if (storeId && stores) {
    store = stores.find(store => store.id === storeId)
  }

  return store
}

export const getUserId = state =>
  pathOr(null, ['api', 'subscription', 'info', 'id'], state)

export const getUserEmail = state =>
  pathOr(null, ['api', 'subscription', 'info', 'email'], state)

export const getUserMagentoId = state =>
  pathOr(null, ['api', 'subscription', 'info', 'magento_id'], state)

export const getUserDeliveryDate = state =>
  pathOr(null, ['merchandiseSets', 'data', 0, 'deliveryDate'], state)

export const getUserRing = state =>
  pathOr(null, ['api', 'subscription', 'info', 'ring', 'id'], state)

export const getProfilesInfo = state =>
  pathOr(null, ['api', 'subscription', 'info', 'profiles'], state)

export const deleteAccountState = state => {
  return pathOr(null, ['account', 'deleteAccount'], state)
}
export const deleteAccountStatus = createSelector(
  deleteAccountState,
  status => status
)

export const isEarlyUserSelector = createSelector(
  getAccountData,
  accountData => {
    if (!accountData.createdAt) {
      return null
    }
    const now = moment()
    const userCreationDate = moment(accountData.createdAt)
    return now.diff(userCreationDate, 'week') < 5
  }
)
