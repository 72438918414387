import { useState } from 'react'

export function useMealFavorite({
  mealId,
  isLikeMarked,
  onLike,
  onUnlike
}: {
  mealId: number
  isLikeMarked: boolean
  onLike: (mealId: number) => void
  onUnlike: (mealId: number) => void
}) {
  const [isLiked, setIsLiked] = useState(isLikeMarked ?? false)

  function toggleFavorite() {
    setIsLiked(() => !isLiked)
    isLikeMarked ? onUnlike(mealId) : onLike(mealId)
  }

  return {
    isLiked,
    toggleFavorite
  }
}
