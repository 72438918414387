import * as Yup from 'yup'
import { Formik, Field, Form } from 'formik'
import { useSelector } from 'react-redux'
import { CuiButton } from 'front-lib'

import { getSubscriptionPlan } from 'src/modules/MyPlan/selectors'
import { getAllowPlanChange } from 'src/redux-api-bridge/selectors'
import { PlanOptionCard } from '../../PlanOptionCard/PlanOptionCard'
import { useChangePlan } from 'src/pages/myPlan/hooks/useChangePlan'
import { useChangePlanModal } from 'src/hooks/useChangePlanModal'
import { useGetAvailablePlans } from 'src/api/plan/useGetAvailablePlans'

export function ChangePlanForm() {
  const subscriptionPlan = useSelector(getSubscriptionPlan)
  const { isUpgrade, close } = useChangePlanModal()
  const { availablePlans } = useGetAvailablePlans()

  const { changePlan } = useChangePlan()

  const currentPlanId = String(subscriptionPlan?.id)

  const allowPlanChange = useSelector(getAllowPlanChange)

  function handleSubmit({ newPlanId }) {
    changePlan(Number(newPlanId))
    close()
  }

  return (
    <Formik
      initialValues={{
        newPlanId: currentPlanId
      }}
      validationSchema={Yup.object().shape({
        newPlanId: Yup.string().notOneOf(
          [currentPlanId],
          'User is currently subscribed to this plan'
        )
      })}
      onSubmit={handleSubmit}>
      {({ values }) => (
        <Form className="change-plan">
          <div
            role="group"
            aria-labelledby="my-radio-group"
            className="change-plan--option-container">
            {availablePlans
              ?.filter(plan => {
                const isBiggerPlan =
                  plan.mealsPerDelivery >= subscriptionPlan.mealsPerDelivery

                return isUpgrade ? isBiggerPlan : true
              })
              .map(plan => {
                const planId = String(plan.id)
                return (
                  <label className="change-plan--option" key={planId}>
                    <Field type="radio" name="newPlanId" value={planId} />
                    <PlanOptionCard
                      plan={plan}
                      selected={planId === values.newPlanId}
                      type={'button'}
                    />
                  </label>
                )
              })}
          </div>
          <div className="change-plan--footer">
            <CuiButton
              type="submit"
              size="large"
              disabled={values.newPlanId === currentPlanId || !allowPlanChange}>
              Update
            </CuiButton>
            <CuiButton onClick={close} size="large" fill="outline">
              Cancel
            </CuiButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}
