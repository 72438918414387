import { atom } from 'jotai'

export const deflectionWithActivePromoModalAtom = atom<{
  isOpen: boolean
  payload: any
  type: 'skip' | 'pause' | 'cancel' | ''
  credits: string
  promotions: {
    offer: string
    promotionActivationId: number
    expirationDate: string
  }[]
}>({
  isOpen: false,
  payload: {},
  type: '',
  credits: '',
  promotions: []
})
