import { useMobileDevice } from 'src/hooks/useMobileDevice'
import { Tooltip } from 'react-tooltip'
import { ReactComponent as Info } from 'src/assets/svg/info-empty-circled.svg'
import './FormItemHeader.scss'

const FormItemHeader = ({ title, info, subtitle }) => {
  const isMobile = useMobileDevice()
  return (
    <div className="preferences-item__header">
      <div className="preferences-item__header-title">
        {title}
        {!!info && (
          <>
            <Info data-tooltip-id={`form-header-${title}`} />
            <Tooltip
              id={`form-header-${title}`}
              className="preferences-item__header-tooltip-container"
              opacity={1}
              closeEvents={{ click: true, mouseleave: true, blur: true }}
              place={isMobile ? 'bottom' : 'top'}
              globalCloseEvents={{ clickOutsideAnchor: true }}
              openOnClick={isMobile}>
              <div className="preferences-item__header-tooltip">{info}</div>
            </Tooltip>
          </>
        )}
      </div>
      <div className="preferences-item__header-subtitle">{subtitle}</div>
    </div>
  )
}

export { FormItemHeader }
