import { handleActions } from 'redux-actions'

import * as mealDetail from './actions'

const initialState = {
  data: null,
  loading: false,
  succeed: false,
  failed: false,
  likeMeal: {
    selectedLikeMeal: null,
    inProgress: false
  }
}

export default handleActions(
  {
    [mealDetail.getMealDetail]: state => ({
      ...state,
      data: null,
      loading: true,
      succeed: false,
      failed: false
    }),
    [mealDetail.getMealDetailSuccess]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      succeed: true,
      failed: false
    }),
    [mealDetail.getMealDetailFail]: state => ({
      ...state,
      data: null,
      loading: false,
      succeed: false,
      failed: true
    }),
    [mealDetail.setLikeMeal]: (state, action) => ({
      ...state,
      likeMeal: {
        selectedMealId: action.payload,
        inProgress: true
      }
    }),
    [mealDetail.cleanLikeMeal]: state => ({
      ...state,
      likeMeal: {
        selectedMealId: null,
        inProgress: false
      }
    })
  },
  initialState
)
