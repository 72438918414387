import moment from 'moment'

import createReducer from '../utils/createReducer'

import * as actions from './actions'
import * as deliveriesActions from '../modules/MyDeliveries/actions'
import * as menuActions from './menu/actions'
import { SURVEY_NAMES } from 'src/constants/menu'

// EXPLICIT SCHEMA FOR REFERENCE, PLEASE MANTAING THIS BRIDGE UPDATED WITH index.js return
const initialState = {
  status: {
    state: 'loading'
  },
  user: {
    info: null,
    addresses: null,
    invoices: null,
    invoicesInFlight: false,
    invoicesSucceed: false
  },
  subscription: {
    error: null,
    info: null,
    deliveryInfoToCheck: null,
    userPlan: null,
    upcommingOrders: null,
    upcomingDays: null,
    skippedUpcomingOrders: null,
    timeslots: null,
    reschedulingOrder: null,
    settings: null
  },
  onboarding: {
    values: {
      withPartner: true,
      partnerName: '',
      userDiets: [],
      userTastes: [],
      userRestrictions: [],
      userAvoids: [],
      partnerDiets: [],
      partnerTastes: [],
      partnerRestrictions: [],
      partnerAvoids: []
    }
  },
  mealDetail: null,
  chefDetail: null,
  chefDetailId: null,
  filters: null,
  stores: null,
  getSubscriptionDataStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  updateStatus: {
    inFlight: false,
    succeeded: false,
    error: null,
    timestamp: moment().unix()
  },
  upcommingOrderStatus: {
    inFlight: false,
    succeeded: false,
    error: null,
    timestamp: moment().unix()
  },
  rescheduleStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  updateUserInfoStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  unskipOrderStatus: {
    skip: false,
    inFlight: false,
    succeeded: false,
    error: null
  },
  createAddressStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  updateAddressStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  setDefaultAddressStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  getAddressesStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  getAvailableDeliveryDaysStatus: {
    inFlight: false,
    succeeded: false,
    error: null,
    zipcode: null
  },
  updateAccountStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  changeSubscriptionPreferencesStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  updateSubscriptionDaysStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  chefDroplineStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  setSubscriptionPlanStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  getPreferencesDataStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  getPublicDataStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  feedbackForm: {
    inFlight: false,
    succeeded: false,
    error: null,
    unsubscribeReasons: []
  },
  schedulingStatus: {
    newDate: null,
    newTimeslot: null,
    scheduled: false,
    cutOff: null
  },
  orderConfirmed: {
    showModal: false,
    date: null,
    timeslot: null
  },
  showAddonsTooltip: false,
  zipcodesByDeliveryZone: {
    zipcodes: null,
    inFlight: false,
    succeeded: false,
    error: false
  },
  profilesStatus: {
    inFlight: false,
    succeeded: false,
    error: false
  },
  createSubscriptionEvent: {
    inFlight: false,
    succeeded: false,
    error: false
  },
  trackingInfo: {
    payload: null,
    inFlight: false,
    succeeded: false,
    error: false
  },
  pastOrders: {
    orders: [],
    inFlight: false,
    succeeded: false,
    error: false
  },
  preferences: {
    diets: [],
    dietaryPreferences: [],
    tastes: [],
    restrictions: [],
    goals: []
  },
  availableMeals: {
    inFlight: false,
    succeeded: false,
    error: false,
    ts: null,
    week: {
      total: 0,
      available: 0,
      unavailable: 0
    },
    day: {
      total: 0,
      available: 0,
      unavailable: 0,
      meals: []
    }
  },
  addMealWishStatus: {
    inFlight: false,
    succeeded: false,
    error: false
  },
  removeMealWishStatus: {
    inFlight: false,
    succeeded: false,
    error: false
  },
  userReferrals: {
    inFlight: false,
    succeed: false,
    error: false,
    history: [],
    stats: {
      count: 0,
      earned: 0
    }
  },
  getUserCurrentCredit: {
    inFlight: false,
    succeeded: false,
    error: false
  },
  userSurvey: {
    inFlight: false,
    succeeded: false,
    error: false,
    hasPendingSurvey: false,
    hasPendingForYouSurvey: false
  },
  idleDeliveryDays: {
    inFlight: false,
    succeed: false,
    error: false,
    availableDays: []
  },
  applyAutopilotPromotionMutation: {
    inFlight: true,
    succeed: false,
    error: false,
    applyAutopilotPromotionMutation: []
  }
}

//TODO: see where add filter to the axion response object, maybe reutilize some API selector or custom redux middleware to clean up
export default createReducer(initialState, {
  [actions.setValuesOnboarding.succeed.type]: (state, action) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      values: action.payload
    }
  }),
  [actions.initApp.succeed.type]: state => ({
    ...state,
    status: {
      state: 'loaded'
    }
  }),
  [actions.initApp.failed.type]: state => ({
    ...state,
    status: {
      state: 'failed'
    }
  }),
  [actions.getSubscriptionData.start.type]: state => ({
    ...state,
    getSubscriptionDataStatus: {
      succeeded: false,
      inFlight: true,
      error: false
    }
  }),
  [actions.getSubscriptionData.failed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      error: action.payload.err
    },
    getSubscriptionDataStatus: {
      succeeded: false,
      inFlight: false,
      error: true
    }
  }),
  [actions.getSubscriptionData.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      ...action.payload
    },
    getSubscriptionDataStatus: {
      succeeded: true,
      inFlight: false,
      error: false
    }
  }),
  [actions.getUserSettings.start.type]: state => ({
    ...state,
    getUserSettingsStatus: {
      succeeded: false,
      inFlight: true,
      error: false
    }
  }),
  [actions.getUserSettings.failed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      error: action.payload.err
    },
    getUserSettingsStatus: {
      succeeded: false,
      inFlight: false,
      error: true
    }
  }),
  [actions.getUserSettings.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      ...action.payload
    },
    getUserSettingsStatus: {
      succeeded: true,
      inFlight: false,
      error: false
    }
  }),
  [actions.getStores.succeed.type]: (state, { payload }) => ({
    ...state,
    stores: [...payload.stores]
  }),
  [actions.getUserPastOrders.start.type]: state => ({
    ...state,
    pastOrders: {
      ...state.pastOrders,
      succeeded: false,
      inFlight: true,
      error: false
    }
  }),
  [actions.getUserPastOrders.succeed.type]: (state, { payload }) => ({
    ...state,
    pastOrders: {
      ...state.pastOrders,
      orders: payload,
      inFlight: false,
      succeeded: true,
      error: false
    }
  }),
  [actions.getUserPastOrders.failed.type]: state => ({
    ...state,
    pastOrders: {
      ...state.pastOrders,
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.getUserData.succeed.type]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      info: { ...action.payload.info }
    }
  }),
  [actions.getChefDetail.start.type]: (state, action) => ({
    ...state,
    chefDetailId: action.payload.chef_id,
    chefDetail: null
  }),
  [actions.getChefDetail.succeed.type]: (state, action) => ({
    ...state,
    chefDetail: {
      ...action.payload.chef,
      chefMenu: [...action.payload.menu]
    }
  }),
  [actions.updateOrder.start.type]: state => ({
    ...state,
    updateStatus: {
      timestamp: state.updateStatus.timestamp,
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.updateOrder.succeed.type]: state => ({
    ...state,
    updateStatus: {
      inFlight: false,
      succeeded: true,
      error: null,
      timestamp: moment().unix()
    },
    subscription: {
      ...state.subscription,
      reschedulingOrder: null
    }
  }),
  [actions.getTimeSlots.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      timeslots: action.payload.timeslots
    }
  }),
  [actions.updateOrder.failed.type]: (state, action) => ({
    ...state,
    updateStatus: {
      timestamp: state.updateStatus.timestamp,
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.updateOrder.clean.type]: state => ({
    ...state,
    updateStatus: {
      timestamp: state.updateStatus.timestamp,
      inFlight: false,
      succeeded: false,
      error: null
    }
  }),
  [actions.rescheduleOrder.start.type]: state => ({
    ...state,
    rescheduleStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.rescheduleOrder.succeed.type]: state => ({
    ...state,
    rescheduleStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.rescheduleOrder.failed.type]: (state, action) => ({
    ...state,
    rescheduleStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.unskipOrder.start.type]: state => ({
    ...state,
    unskipOrderStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.unskipOrder.succeed.type]: state => {
    return {
      ...state,
      unskipOrderStatus: {
        inFlight: false,
        succeeded: true,
        error: null
      }
    }
  },
  [actions.unskipOrder.failed.type]: (state, action) => ({
    ...state,
    unskipOrderStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.getAddresses.start.type]: state => ({
    ...state,
    getAddressesStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.getAddresses.failed.type]: (state, action) => ({
    ...state,
    getAddressesStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.getAddresses.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        ring_id: action.payload.ring_id,
        addresses: action.payload.addresses
      }
    },
    getAddressesStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.getAvailableDeliveryDays.start.type]: state => ({
    ...state,
    getAvailableDeliveryDaysStatus: {
      inFlight: true,
      succeeded: false,
      error: null,
      zipcode: null
    }
  }),
  [actions.getAvailableDeliveryDays.failed.type]: (state, action) => ({
    ...state,
    getAvailableDeliveryDaysStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err,
      zipcode: null
    }
  }),
  [actions.getAvailableDeliveryDays.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        availableDeliveryDays: action.payload.availableDeliveryDays
      }
    },
    getAvailableDeliveryDaysStatus: {
      inFlight: false,
      succeeded: true,
      error: null,
      zipcode: action.payload.zipcode
    }
  }),
  [actions.deleteAddress.start.type]: state => ({
    ...state,
    deleteAddressStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.deleteAddress.succeed.type]: state => ({
    ...state,
    deleteAddressStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.getInvoices.start.type]: state => ({
    ...state,
    user: {
      ...state.user,
      invoicesInFlight: true
    }
  }),
  [actions.getInvoices.succeed.type]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      invoices: action.payload.invoices,
      invoicesInFlight: false,
      invoicesSucceed: true
    }
  }),

  [actions.updateUserInfo.start.type]: state => ({
    ...state,
    updateUserInfoStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.updateUserInfo.succeed.type]: state => ({
    ...state,
    updateUserInfoStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.updateUserInfo.failed.type]: (state, action) => ({
    ...state,
    updateUserInfoStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.updateAddress.start.type]: state => ({
    ...state,
    updateAddressStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.updateAddress.clean.type]: state => ({
    ...state,
    updateAddressStatus: {
      inFlight: false,
      succeeded: false,
      error: null
    }
  }),
  [actions.updateAddress.succeed.type]: state => ({
    ...state,
    updateAddressStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.updateAddress.failed.type]: (state, action) => ({
    ...state,
    updateAddressStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.setDefaultAddress.start.type]: state => ({
    ...state,
    setDefaultAddressStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.setDefaultAddress.succeed.type]: state => ({
    ...state,
    setDefaultAddressStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.setDefaultAddress.failed.type]: (state, action) => ({
    ...state,
    setDefaultAddressStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.updateSubscriptionDays.start.type]: state => ({
    ...state,
    subscription: {
      ...state.subscription,
      deliveryInfoToCheck: null
    },
    updateSubscriptionDaysStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.updateSubscriptionDays.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        ...action.payload.subscription
      },
      deliveryInfoToCheck: null
    },
    updateSubscriptionDaysStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.updateSubscriptionDays.failed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      deliveryInfoToCheck: null
    },
    updateAddressStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.updateSubscriptionDays.clean.type]: state => ({
    ...state,
    subscription: {
      ...state.subscription,
      deliveryInfoToCheck: null
    },
    updateSubscriptionDaysStatus: {
      inFlight: false,
      succeeded: false,
      error: null
    }
  }),
  [actions.setDeliveryInfoToCheck.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      deliveryInfoToCheck: action.payload.deliveryInfo
    }
  }),
  [actions.changeSubscriptionPreferences.start.type]: state => ({
    ...state,
    changeSubscriptionPreferencesStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.changeSubscriptionPreferences.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        profiles: state.subscription.info.profiles.map(p => {
          const { payload: prof } = action.payload
          if (!p.id) {
            return { ...prof, id: '' }
          }
          if (p.id !== prof.id) {
            return p
          }
          return { ...prof }
        })
      }
    },
    changeSubscriptionPreferencesStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.removeProfile.start.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        profiles: state.subscription.info.profiles.filter(
          p => p.id !== action.payload.profileId
        )
      }
    }
  }),
  [actions.getProfilesData.start.type]: state => ({
    ...state,
    profilesStatus: {
      inFlight: true,
      succeeded: false,
      error: false
    }
  }),
  [actions.getProfilesData.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        name: action.payload.name,
        profiles: action.payload.profiles
      }
    },
    profilesStatus: {
      inFlight: false,
      succeeded: true,
      error: false
    }
  }),
  [actions.getProfilesData.failed.type]: state => ({
    ...state,
    profilesStatus: {
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.getProfilesData.clean.type]: state => ({
    ...state,
    profilesStatus: {
      inFlight: false,
      succeeded: false,
      error: false
    }
  }),
  [actions.prepopulatePartnerProfile.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        profiles: [...state.subscription.info.profiles, action.payload]
      }
    }
  }),
  [actions.cancelPartnerCreation.type]: state => {
    const profiles = state.subscription.info.profiles
    return {
      ...state,
      subscription: {
        ...state.subscription,
        info: {
          ...state.subscription.info,
          profiles: profiles.slice(0, profiles.length - 1)
        }
      }
    }
  },
  [actions.getUpcomingDays.start.type]: state => ({
    ...state,
    upcommingOrderStatus: {
      ...state.upcommingOrderStatus,
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.getUpcomingDays.failed.type]: (state, action) => ({
    ...state,
    upcommingOrderStatus: {
      ...state.upcommingOrderStatus,
      inFlight: false,
      succeeded: false,
      error: action.payload.error
    }
  }),
  [actions.getUpcomingDays.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      upcomingDays: action.payload.upcomingDays
    },
    upcommingOrderStatus: {
      inFlight: false,
      succeeded: true,
      error: null,
      timestamp: moment().unix()
    }
  }),
  [actions.reschedulingOrder.start.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      reschedulingOrder: action.payload.order
    }
  }),
  [actions.chefDropline.start.type]: state => ({
    ...state,
    chefDroplineStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.chefDropline.succeed.type]: state => ({
    ...state,
    chefDroplineStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.chefDropline.failed.type]: (state, action) => ({
    ...state,
    chefDroplineStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.setSubscriptionPlan.start.type]: state => ({
    ...state,
    setSubscriptionPlanStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.setSubscriptionPlan.succeed.type]: state => ({
    ...state,
    setSubscriptionPlanStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.setSubscriptionPlan.failed.type]: (state, action) => ({
    ...state,
    setSubscriptionPlanStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.setSubscriptionPlan.clean.type]: state => ({
    ...state,
    setSubscriptionPlanStatus: {
      inFlight: false,
      succeeded: false,
      error: null
    }
  }),
  [actions.getPreferencesData.start.type]: state => ({
    ...state,
    getPreferencesDataStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.getPreferencesData.succeed.type]: (state, action) => ({
    ...state,
    preferences: {
      ...state.preferences,
      ...action.payload
    },
    getPreferencesDataStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.getPreferencesData.failed.type]: (state, action) => ({
    ...state,
    getPreferencesDataStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    }
  }),
  [actions.getPublicData.start.type]: state => ({
    ...state,
    getPublicDataStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.getPublicData.succeed.type]: (state, action) => {
    return {
      ...state,
      public: {
        ...action.payload.data
      },
      getPublicDataStatus: {
        inFlight: false,
        succeeded: true,
        error: null
      }
    }
  },
  [actions.getPublicData.failed.type]: (state, action) => ({
    ...state,
    getPublicDataStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload.error
    }
  }),
  [actions.changeTip.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        tip: action.payload.tip
      }
    }
  }),
  [actions.getUnsubscribeReasons.start.type]: state => ({
    ...state,
    feedbackForm: {
      ...state.feedbackForm,
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.getUnsubscribeReasons.succeed.type]: (state, action) => ({
    ...state,
    feedbackForm: {
      inFlight: false,
      succeeded: true,
      error: null,
      unsubscribeReasons: action.payload.unsubscribeReasons
    }
  }),
  [actions.getUnsubscribeReasons.failed.type]: state => ({
    ...state,
    feedbackForm: {
      ...state.feedbackForm,
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.changeDateAndTimeslot.type]: (
    state,
    {
      payload: {
        newDate = null,
        newTimeslot = null,
        scheduled = false,
        cutOff = null
      }
    }
  ) => {
    return {
      ...state,
      schedulingStatus: {
        newDate,
        newTimeslot,
        scheduled,
        cutOff
      }
    }
  },
  [actions.toggleOrderConfirmed.type]: (state, { payload }) => ({
    ...state,
    orderConfirmed: {
      showModal:
        typeof payload === 'boolean'
          ? payload
          : !state.orderConfirmed.showModal,
      date: payload && payload.date,
      timeslot: payload && payload.timeslot
    }
  }),
  [deliveriesActions.turnOffRedirectUserToOrder.start.type]: state => {
    const settings = state.subscription.settings || []
    return {
      ...state,
      subscription: {
        ...state.subscription,
        settings: [{ key: 'redirectToOrder', value: 'false' }, ...settings]
      }
    }
  },
  [actions.setSetting.start.type]: (state, { payload }) => {
    const settings = state.subscription.settings || []
    const setting = settings.find(i => i.key === payload.key)

    if (typeof setting === 'undefined') {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          settings: [
            ...state.subscription.settings,
            { key: payload.key, value: payload.value }
          ]
        }
      }
    }
    return {
      ...state,
      subscription: {
        ...state.subscription,
        settings: settings.map(n => {
          if (n.key === payload.key) {
            return {
              ...n,
              value: payload.value
            }
          }
          return n
        })
      }
    }
  },
  [actions.toggleAddonsTooltip.type]: (state, { payload }) => {
    const settings = state.subscription.settings
    return {
      ...state,
      showAddonsTooltip:
        payload && typeof payload.forceClose === 'boolean'
          ? false
          : !state.showAddonsTooltip,
      subscription: {
        ...state.subscription,
        settings:
          payload && payload.forceClose
            ? [{ key: 'showaddonstooltip', value: '0' }, ...settings]
            : settings
      }
    }
  },
  [actions.clearNotification.start.type]: (state, { payload }) => {
    const settings = state.subscription.settings || []
    return {
      ...state,
      subscription: {
        ...state.subscription,
        settings: settings.map(n => {
          if (n.key === payload.key) {
            return {
              ...n,
              value: false
            }
          }
          return n
        })
      }
    }
  },
  [actions.getZipcodesByDeliveryZone.start.type]: state => {
    return {
      ...state,
      zipcodesByDeliveryZone: {
        error: false,
        succeeded: false,
        inFlight: true,
        zipcodes: null
      }
    }
  },
  [actions.getZipcodesByDeliveryZone.succeed.type]: (state, { payload }) => {
    return {
      ...state,
      zipcodesByDeliveryZone: {
        error: false,
        succeeded: true,
        inFlight: false,
        zipcodes: payload
      }
    }
  },
  [actions.getZipcodesByDeliveryZone.failed.type]: state => {
    return {
      ...state,
      zipcodesByDeliveryZone: {
        error: true,
        succeeded: false,
        inFlight: false,
        zipcodes: null
      }
    }
  },
  [actions.createSubscriptionEvent.start.type]: state => {
    return {
      ...state,
      createSubscriptionEvent: {
        inFlight: true,
        succeeded: false,
        error: false
      }
    }
  },
  [actions.createSubscriptionEvent.succeed.type]: (state, { payload }) => {
    return {
      ...state,
      createSubscriptionEvent: {
        inFlight: false,
        succeeded: true,
        error: false
      },
      subscription: {
        ...state.subscription,
        info: {
          ...state.subscription.info,
          status: ['unhold', 'unpause', 'subscribe'].includes(payload.event)
            ? 'active'
            : 'inactive',
          status_event: {
            ...payload
          }
        }
      }
    }
  },
  [actions.createSubscriptionEvent.failed.type]: state => {
    return {
      ...state,
      createSubscriptionEvent: {
        inFlight: false,
        succeeded: false,
        error: true
      }
    }
  },
  [actions.getTrackingInfo.start.type]: state => {
    return {
      ...state,
      trackingInfo: {
        payload: null,
        inFlight: true,
        succeeded: false,
        error: false
      }
    }
  },
  [actions.getTrackingInfo.succeed.type]: (state, { payload }) => {
    return {
      ...state,
      trackingInfo: {
        payload,
        inFlight: false,
        succeeded: true,
        error: false
      }
    }
  },
  [actions.getTrackingInfo.failed.type]: state => {
    return {
      ...state,
      trackingInfo: {
        payload: null,
        inFlight: false,
        succeeded: false,
        error: true
      }
    }
  },
  [actions.addMealWish.succeed.type]: (state, { payload }) => {
    const { mealWishes, profile: profileId } = payload
    return {
      ...state,
      subscription: {
        ...state.subscription,
        info: {
          ...state.subscription.info,
          profiles: state.subscription.info.profiles.map(p =>
            p.id === profileId ? { ...p, mealWishes } : p
          )
        }
      },
      addMealWishStatus: {
        inFlight: false,
        succeeded: true,
        error: false
      }
    }
  },
  [actions.addMealWish.start.type]: state => {
    return {
      ...state,
      addMealWishStatus: {
        inFlight: true,
        succeeded: false,
        error: false
      }
    }
  },
  [actions.addMealWish.failed.type]: state => {
    return {
      ...state,
      addMealWishStatus: {
        inFlight: false,
        succeeded: false,
        error: true
      }
    }
  },
  [actions.addMealWish.clean.type]: state => {
    return {
      ...state,
      addMealWishStatus: {
        inFlight: false,
        succeeded: false,
        error: false
      }
    }
  },
  [actions.removeMealWish.succeed.type]: (state, { payload }) => {
    const { mealWishes, profile: profileId } = payload
    return {
      ...state,
      subscription: {
        ...state.subscription,
        info: {
          ...state.subscription.info,
          profiles: state.subscription.info.profiles.map(p =>
            p.id === profileId ? { ...p, mealWishes } : p
          )
        }
      },
      removeMealWishStatus: {
        inFlight: false,
        succeeded: true,
        error: false
      }
    }
  },
  [actions.removeMealWish.failed.type]: state => {
    return {
      ...state,
      removeMealWishStatus: {
        inFlight: false,
        succeeded: false,
        error: true
      }
    }
  },
  [actions.removeMealWish.start.type]: state => {
    return {
      ...state,
      removeMealWishStatus: {
        inFlight: true,
        succeeded: false,
        error: false
      }
    }
  },
  [actions.removeMealWish.clean.type]: state => {
    return {
      ...state,
      removeMealWishStatus: {
        inFlight: false,
        succeeded: false,
        error: false
      }
    }
  },
  [actions.addMealAvoid.succeed.type]: (state, { payload }) => {
    const { mealAvoids, profile: profileId } = payload
    return {
      ...state,
      subscription: {
        ...state.subscription,
        info: {
          ...state.subscription.info,
          profiles: state.subscription.info.profiles.map(p =>
            p.id === profileId ? { ...p, mealAvoids } : p
          )
        }
      }
    }
  },
  [actions.removeMealAvoid.succeed.type]: (state, { payload }) => {
    const { mealAvoids, profile: profileId } = payload
    return {
      ...state,
      subscription: {
        ...state.subscription,
        info: {
          ...state.subscription.info,
          profiles: state.subscription.info.profiles.map(p =>
            p.id === profileId ? { ...p, mealAvoids } : p
          )
        }
      }
    }
  },
  [actions.getAvailableMeals.start.type]: (state, { payload }) => {
    return {
      ...state,
      availableMeals: {
        ...state.availableMeals,
        inFlight: true,
        succeed: false,
        error: false,
        ts: payload.ts
      }
    }
  },
  [actions.getAvailableMeals.succeed.type]: (state, { payload }) => {
    const { availableMeals, ts } = payload

    if (state.availableMeals.ts === ts) {
      return {
        ...state,
        availableMeals: {
          ...state.availableMeals,
          ...availableMeals,
          inFlight: false,
          succeed: true,
          error: false
        }
      }
    }
    return state
  },
  [actions.getAvailableMeals.failed.type]: (state, { payload }) => {
    if (state.availableMeals.ts === payload.ts) {
      return {
        ...state,
        availableMeals: {
          ...state.availableMeals,
          inFlight: false,
          succeed: false,
          error: true
        }
      }
    }
    return state
  },
  [actions.addMealWishRequest.succeed.type]: (state, { payload }) => {
    const { mealWishRequests, profile: profileId } = payload
    return {
      ...state,
      subscription: {
        ...state.subscription,
        info: {
          ...state.subscription.info,
          profiles: state.subscription.info.profiles.map(p =>
            p.id === profileId ? { ...p, mealWishRequests } : p
          )
        }
      }
    }
  },
  [actions.getUserReferrals.start.type]: state => {
    return {
      ...state,
      userReferrals: {
        inFlight: true,
        succeed: false,
        error: false,
        history: [],
        stats: {
          count: 0,
          earned: 0
        }
      }
    }
  },
  [actions.getUserReferrals.succeed.type]: (state, { payload }) => {
    return {
      ...state,
      userReferrals: {
        ...payload,
        inFlight: false,
        succeed: true,
        error: false
      }
    }
  },
  [actions.getUserReferrals.failed.type]: (state, { payload }) => {
    return {
      ...state,
      userReferrals: {
        ...payload,
        inFlight: false,
        succeed: false,
        error: true
      }
    }
  },
  [menuActions.resetUserMenu]: state => {
    return {
      ...state,
      schedulingStatus: {
        newDate: null,
        newTimeslot: null,
        scheduled: false,
        cutOff: null
      }
    }
  },
  [actions.getUserCurrentCredit.start.type]: state => ({
    ...state,
    getUserCurrentCreditStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.getUserCurrentCredit.failed.type]: state => ({
    ...state,
    getUserCurrentCreditStatus: {
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.getUserCurrentCredit.succeed.type]: (state, { payload }) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: {
        ...state.subscription.info,
        currentCredit: payload
      }
    },
    getUserCurrentCreditStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.getUserSurvey.start.type]: state => ({
    ...state,
    userSurvey: {
      inFlight: true,
      succeeded: false,
      error: null,
      hasPendingSurvey: false,
      hasPendingForYouSurvey: false
    }
  }),
  [actions.getUserSurvey.failed.type]: state => ({
    ...state,
    userSurvey: {
      inFlight: false,
      succeeded: false,
      error: true,
      hasPendingSurvey: false,
      hasPendingForYouSurvey: false
    }
  }),
  [actions.getUserSurvey.succeed.type]: (state, { payload }) => ({
    ...state,
    userSurvey: {
      inFlight: false,
      succeeded: true,
      hasPendingSurvey: payload.hasPendingSurvey,
      hasPendingForYouSurvey: payload.customSurveys?.find(
        s => s.name === SURVEY_NAMES.FOR_YOU
      )?.hasPendingSurvey
    }
  }),
  [actions.submitSurvey.succeed.type]: (state, { payload }) => ({
    ...state,
    userSurvey: {
      ...state.userSurvey,
      hasPendingForYouSurvey:
        payload === SURVEY_NAMES.FOR_YOU
          ? false
          : state.userSurvey.hasPendingForYouSurvey
    }
  }),
  [actions.getUserPromotions.start.type]: state => ({
    ...state,
    userPromotions: {
      inFlight: true,
      succeeded: false,
      error: null,
      data: null
    }
  }),
  [actions.getUserPromotions.failed.type]: state => ({
    ...state,
    userPromotions: {
      inFlight: false,
      succeeded: false,
      error: true,
      data: null
    }
  }),
  [actions.getUserPromotions.succeed.type]: (state, { payload }) => ({
    ...state,
    userPromotions: {
      inFlight: false,
      succeeded: true,
      data: payload
    }
  }),
  [actions.getIdleDeliveryDays.start.type]: state => {
    return {
      ...state,
      idleDeliveryDays: {
        inFlight: true,
        succeed: false,
        error: false,
        availableDays: []
      }
    }
  },
  [actions.getIdleDeliveryDays.succeed.type]: (state, { payload }) => {
    return {
      ...state,
      idleDeliveryDays: {
        availableDays: payload.days,
        inFlight: false,
        succeed: true,
        error: false
      }
    }
  },
  [actions.applyAutoPilotPromotion.start.type]: state => {
    return {
      ...state,
      applyAutopilotPromotionMutation: {
        inFlight: true,
        succeed: false,
        error: false,
        applyAutopilotPromotionMutation: []
      }
    }
  },
  [actions.applyAutoPilotPromotion.succeed.type]: (state, { payload }) => {
    return {
      ...state,
      applyAutopilotPromotionMutation: {
        applyAutopilotPromotionMutation: payload,
        inFlight: false,
        succeed: true,
        error: false
      }
    }
  },
  [actions.applyAutoPilotPromotion.failed.type]: state => {
    return {
      ...state,
      applyAutopilotPromotionMutation: {
        inFlight: false,
        succeed: false,
        error: true,
        applyAutopilotPromotionMutation: []
      }
    }
  }
})
