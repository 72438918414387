import { gql, useLazyQuery } from '@apollo/client'

export const GET_AVAILABLE_CREDITS_AND_PROMOTIONS_QUERY = gql`
  query getAvailableCreditsAndPromotions {
    creditsAndPromotionsPagePromotionsTab {
      promotions {
        promotionActivationId
        type
        offer
        status
        expirationDate
        feeForLife
        active
      }
    }
    creditsAndPromotionsPageCreditsResume {
      creditsAvailable
    }
  }
`

export function useGetAvailableCreditsAndPromotions() {
  return useLazyQuery<{
    creditsAndPromotionsPagePromotionsTab: {
      promotions: {
        promotionActivationId: number
        type: string
        offer: string
        status: string | null
        expirationDate: string | null
        feeForLife: boolean
        active: boolean
      }[]
    }
    creditsAndPromotionsPageCreditsResume: {
      creditsAvailable: string
    }
  }>(GET_AVAILABLE_CREDITS_AND_PROMOTIONS_QUERY, {
    fetchPolicy: 'cache-and-network'
  })
}
