import { useDispatch, useSelector } from 'react-redux'

import { getSubscriptionPlan, getUserDeliveryInfoSelector } from '../selectors'
import { setSubscriptionPlan } from '../../../redux-api-bridge'

import tracking from '../../../shared/tracking'
import { useGetAvailablePlans } from 'src/api/plan/useGetAvailablePlans'

export function useChangePlan() {
  const dispatch = useDispatch()

  const userDeliveryInfo = useSelector(getUserDeliveryInfoSelector)
  const subscriptionPlan = useSelector(getSubscriptionPlan)
  const { availablePlans } = useGetAvailablePlans()

  function changePlan(planId: number) {
    const selectedPlan = availablePlans.find(plan => plan.id === planId)

    const selectedDeliveryDays = userDeliveryInfo?.selectedDeliveryDays || []
    const selectedTimeslot = userDeliveryInfo?.selectedTimeslot || {
      start: '',
      end: ''
    }

    tracking.track('Meal Plan Updated', {
      old_plan_id: subscriptionPlan.id,
      old_plan_name: subscriptionPlan.title,
      new_plan_id: selectedPlan.id,
      new_plan_name: selectedPlan.title
    })

    dispatch(
      setSubscriptionPlan({
        plan: planId,
        deliveryDays: {
          days: selectedDeliveryDays.map(d => d.day),
          start: selectedTimeslot.start,
          end: selectedTimeslot.end
        }
      })
    )
  }

  return { changePlan }
}
