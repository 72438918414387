import React, { lazy } from 'react'
import { useSelector } from 'react-redux'
import { LoginPage } from '../modules/Login'
import { MyPlanPage } from 'src/pages/myPlan/MyPlanPage'
import { Redirect } from 'react-router-dom'
import RedirectExternalLink from '../components/RedirectExternalLink'
import { Resurrection } from '../screens/resurrection'
import PromotionPage from '../screens/promotion'
import { RoutePaths } from './routePaths'
import { getSubscriptionStatus } from '../modules/MyDeliveries/selectors'
import withLayout from './hocs/withLayout'
import withQueryParams from '../hocs/withQueryParams'
import withTracking from './hocs/withTracking'
import { USER_STATUS } from 'src/modules/MyPlan/constants'
import { useTrackSKUExperimentViewed } from 'src/hooks/useTrackSKUExperimentViewed'
import { useFlag } from '@unleash/proxy-client-react'
import {
  FF_EATER_PROFILE,
  FF_EATER_PROFILE_UI
} from 'src/constants/feature-flags'
import PreferencesPage from 'src/pages/preferences/PreferencesPage'

const MyPlan = lazy(() => import('../modules/MyPlan'))
const ChefDetails = lazy(() => import('../modules/ChefDetail'))

const NutritionDashboard = lazy(() => import('../modules/NutritionDashboard'))
const Notifications = lazy(() =>
  import('../modules/Inbox/screens/Notifications')
)
const OrdersPage = lazy(() => import('src/pages/orders/OrdersPage'))
const ReferralPage = lazy(() => import('../modules/Referral'))
const PersonalizeMeals = lazy(() => import('../modules/PersonalizeMeals'))
const FeedbackForm = lazy(() => import('../modules/FeedbackForm'))
const CancelFlow = lazy(() => import('../modules/CancelFlow'))
const MyAccount = lazy(() =>
  import('../modules/MyAccount/MyAccount').then(module => ({
    default: module.MyAccount
  }))
)
const CreditsAndPromotionsPage = lazy(() =>
  import('src/pages/creditsAndPromotions/CreditsAndPromotionsPage')
)
const Wishlist = lazy(() => import('../modules/Wishlist'))
const Reactivation = lazy(() => import('../screens/reactivation'))
const OnHold = lazy(() => import('../modules/OnHold'))
const ValidateCC = lazy(() => import('../modules/ValidateCC'))
const Logout = lazy(() => import('../modules/Logout'))
const Menu = lazy(() => import('../modules/Menu'))
const MealDetail = lazy(() => import('../modules/MealDetail'))
const Recipe = lazy(() => import('../modules/Recipe/Recipe'))
const MonthlyPlanPage = lazy(() =>
  import('src/pages/monthlyPlan/MonthlyPlanPage')
)
const DownloadOurApp = lazy(() =>
  import('../modules/DownloadOurApp/DownloadOurApp')
)
const NewPaymentMethodCampaignPage = lazy(() =>
  import('src/pages/newPaymentMethodCampaign/NewPaymentMethodCampaignPage')
)
const legacyRoutes = {
  '/reactivation': '/reactivate'
}

const ReviewChallengePage = lazy(() =>
  import('src/pages/reviewChallenge/ReviewChallengePage')
)

const RepeatChefMealsPage = lazy(() =>
  import('src/pages/repeatChefsMeals/RepeatChefMealsPage')
)

const MealsList = lazy(() => import('src/pages/mealsList/MealsList'))

// URL params that are used by brightback
// ?origin=bb_promotion
export const URL_PARAMS_BB_PROMOTION = 'bb_promotion'

export const routes = [
  {
    name: 'Meal Selection',
    path: ['/meals', '/nextMenu'],
    component: withQueryParams(['debug'])(withLayout(withTracking(MyPlan))),
    private: true,
    routes: [
      {
        name: 'Collection Home',
        path: '/meals/collection/:collectionId'
      }
    ]
  },
  {
    name: 'Orders',
    path: [RoutePaths.ORDERS, `${RoutePaths.ORDERS}nextOrder`],
    component: function OrdersPageWrapper(props) {
      useTrackSKUExperimentViewed()
      const match = (props.location.hash || '').match(/^(#\/)(.*)/)
      const subscriptionStatus = useSelector(getSubscriptionStatus)
      if (subscriptionStatus === USER_STATUS.INACTIVE) {
        return <Redirect to="/reactivate" />
      }
      if (match) {
        const to = `/${legacyRoutes[match[2]] || match[2]}`
        return <Redirect to={to} />
      }
      return <OrdersPage />
    },
    private: true,
    exact: true,
    trackAs: 'Subscription Home'
  },
  {
    name: 'Chef Profile',
    path: '/chefs/:chefId',
    component: withLayout(withTracking(ChefDetails)),
    private: false
  },
  {
    name: 'My Plan',
    path: RoutePaths.PLAN,
    component: withLayout(withTracking(MyPlanPage)),
    exact: true,
    private: true
  },
  {
    name: 'Home Product List',
    path: RoutePaths.MEALS_LIST,
    component: withLayout(withTracking(MealsList)),
    exact: true,
    private: true
  },
  {
    label: 'Account Settings',
    path: RoutePaths.ACCOUNT,
    component: withLayout(withTracking(MyAccount)),
    exact: true,
    private: true
  },
  {
    path: '/account/plan/upgrade',
    label: 'Upgrade Plan',
    component: withLayout(withTracking(MyAccount)),
    exact: true,
    private: true
  },
  {
    path: '/account/plan/change',
    label: 'Change Plan',
    component: withLayout(withTracking(MyAccount)),
    exact: true,
    private: true
  },
  {
    path: '/account/payments',
    label: 'Payments',
    component: withLayout(withTracking(MyAccount)),
    exact: true,
    private: true
  },
  {
    path: RoutePaths.HISTORY,
    label: 'Past Deliveries',
    component: withLayout(withTracking(MyAccount)),
    exact: true,
    private: true
  },
  {
    name: 'Nutritions',
    path: '/nutrition',
    component: withLayout(withTracking(NutritionDashboard)),
    private: true
  },
  {
    name: 'Notifications',
    path: '/notifications',
    component: withLayout(withTracking(Notifications)),
    private: true
  },
  {
    name: 'Referral',
    path: '/referral',
    component: withLayout(withTracking(ReferralPage)),
    private: true
  },
  {
    name: 'Personalize',
    path: '/personalize',
    component: withLayout(
      withTracking(props => {
        const isEnabled = useFlag(FF_EATER_PROFILE)
        const isEnabledUI = useFlag(FF_EATER_PROFILE_UI)
        if (isEnabled && isEnabledUI) {
          return <PreferencesPage {...props} />
        }
        return <PersonalizeMeals {...props} />
      })
    ),
    private: true,
    trackAs: 'Preferences',
    id: 'personalize'
  },
  {
    name: 'Feedback',
    path: '/feedback',
    component: withLayout(withTracking(FeedbackForm)),
    private: true
  },
  {
    name: 'Cancel Subscription',
    path: '/cancel-subscription',
    component: withLayout(withTracking(CancelFlow)),
    private: true
  },
  {
    name: 'Promotions',
    path: ['/promotions'],
    component: withLayout(withTracking(CreditsAndPromotionsPage)),
    private: true
  },
  {
    name: 'Favorites',
    path: ['/wishlist', '/favorites'],
    component: withLayout(withTracking(Wishlist)),
    private: true
  },
  {
    name: 'Reactivate',
    path: '/reactivate',
    component: withLayout(Reactivation),
    private: true,
    trackAs: 'Reactivations'
  },
  {
    name: 'Hold',
    path: '/hold',
    component: withLayout(withTracking(OnHold)),
    private: true
  },
  {
    name: 'Validate cc',
    path: '/validate-cc',
    component: withLayout(withTracking(ValidateCC)),
    private: true
  },
  {
    name: 'Login',
    path: '/login',
    component: withTracking(LoginPage)
  },
  {
    name: 'Logout',
    path: '/logout',
    component: withTracking(Logout)
  },
  {
    name: 'Signup',
    path: '/signup',
    component: withTracking(props => {
      const search = props && props.location ? props.location.search : ''
      return props.authed ? (
        <Redirect to={RoutePaths.ORDERS} />
      ) : (
        <RedirectExternalLink
          url={`${process.env.REACT_APP_SIGNUP_URL}/${search}`}
        />
      )
    })
  },
  {
    name: 'Menu',
    path: '/menu',
    component: withTracking(Menu)
  },
  {
    name: 'Meal Detail',
    path: '/products/:productId',
    component: withLayout(withTracking(MealDetail)),
    private: true
  },
  {
    name: 'Recipe',
    path: '/recipes/:recipeId',
    component: withLayout(withTracking(Recipe)),
    private: false
  },
  {
    path: '/resurrection-landing-v1',
    component: withTracking(props => (
      <Resurrection {...props} text="Get your first order for free!" />
    ))
  },
  {
    path: '/resurrection-landing-v2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Get your first order for free and 50% off on your first 4 weeks"
        coupon="REACTIVATION504WEEKS"
      />
    ))
  },
  {
    path: '/resurrection-landing-v3',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Get 35% off on your first 4 weeks"
        coupon="REACTIVATION354WEEKS"
      />
    ))
  },
  {
    path: '/resurrection-landing-v4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Get 40% off each week for your first 4 weeks"
        coupon="REACTIVATION404WEEKS"
      />
    ))
  },
  {
    path: '/resurrection-landing-08C',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Get your first order for free and 50% off on your first 6 weeks"
        coupon="REACTIVATION506WEEKS"
      />
    ))
  },
  {
    path: '/resurrection-landing-08O',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Get 40% off on your first 6 weeks"
        coupon="REACTIVATION406WEEKS"
      />
    ))
  },
  {
    path: '/resurrection-landing-40',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="$80 off on Your First 2 Orders"
        coupon="REACTIVATION40X2"
        browserTitle="Let's start fresh | Come back and get $80"
        conditionsText="First two orders confirmed within 60 days of restarting deliveries will have $40 off each."
      />
    ))
  },
  {
    path: '/resurrection-landing-4x25',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Come back and get $100 on your first 4 orders"
        coupon="REACTIVATION4X25"
        browserTitle="Let's start fresh | Come back and get $100 on your first 4 orders"
        conditionsText="First four orders confirmed within 60 days of restarting deliveries will have $25 off each."
      />
    ))
  },
  {
    path: '/resurrection-landing-40off',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Come back and get 40% on your first order"
        coupon="REACTIVATION40"
        browserTitle="Let's start fresh | Come back and get 40% on your first order"
      />
    ))
  },
  {
    path: '/resurrection-landing-40X4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        text="Get $160 off your first four weeks back."
        coupon="REACTIVATION40X4"
        browserTitle="Let's start fresh | Come back and get $160"
        conditionsText="First four orders confirmed within 60 days of restarting deliveries will have $40 off each."
      />
    ))
  },
  {
    path: '/resurrection-landing-50off',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="50% off Your First Two Weeks"
        coupon="REACTIVATION50OFF2WEEKS"
        browserTitle="Let's start fresh | Come back and get 50% on your first order."
        conditionsText="First two orders confirmed within 60 days of restarting deliveries will have 50% off"
      />
    ))
  },
  {
    path: '/resurrection-landing-35offx4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="35% off Your First Four Weeks"
        coupon="REACTIVATION35OFF4WEEKS"
        browserTitle="Let's start fresh | Come back and get 35% off Your First Four Weeks."
        conditionsText="First four orders confirmed within 60 days of restarting deliveries will have 35% off"
      />
    ))
  },
  {
    path: '/resurrection-landing-25offx2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="25% off Your First Two Weeks"
        coupon="REACTIVATION25OFF2WEEKS"
        browserTitle="Let's start fresh | Come back and get 25% off Your First Two Weeks."
        conditionsText="First two orders confirmed within 60 days of restarting deliveries will have 25% off"
      />
    ))
  },
  {
    path: '/resurrection-20x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="20% off on your First Four Weeks"
        coupon="REACTIVATION20%x4WEEKS"
        browserTitle="Let’s start fresh | Come back and get 20% on your first four orders."
        conditionsText="First four consecutive orders confirmed after restarting deliveries will have 20% off"
      />
    ))
  },
  {
    path: '/resurrection-10x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="10% off on your First Four Weeks"
        coupon="REACTIVATION10%x4WEEKS"
        browserTitle="Let’s start fresh | Come back and get 10% on your first four orders."
        conditionsText="First four consecutive orders confirmed after restarting deliveries will have 10% off"
      />
    ))
  },
  {
    path: '/resurrection-20x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="20% off on your First Two Weeks"
        coupon="REACTIVATION20%x2WEEKS"
        browserTitle="Let's start fresh | Come back and get 20% on your first two orders."
        conditionsText="First two consecutive orders confirmed after restarting deliveries will have 20% off"
      />
    ))
  },
  {
    path: '/resurrection-8x17',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="$8 per meal in your next 17 weeks"
        coupon="resurrection8x17"
        browserTitle="Let's start fresh | Come back and get $8 per meals in your next 17 weeks"
        conditionsText="Next 17 consecutive orders confirmed after restarting deliveries will have $8 per meal"
      />
    ))
  },
  {
    path: '/resurrection-85x17',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="$8.5 per meal in your next 17 weeks"
        coupon="resurrection8.5x17"
        browserTitle="Let's start fresh | Come back and get $8.5 per meals in your next 17 weeks"
        conditionsText="Next 17 consecutive orders confirmed after restarting deliveries will have $8.5 per meal"
      />
    ))
  },
  {
    path: '/fridayresurrection-8x17',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="$8 per meal on your first 17 Weeks back"
        coupon="FRIDAYREACT$8MEAL"
        browserTitle="Don't miss our Black Friday deal | Resubscribe now & pay just $8 per meal for 17 weeks."
        conditionsText="Enjoy this unique price for 17 weeks after restarting deliveries"
      />
    ))
  },
  {
    path: '/fridayresurrection-35x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="35% off on your First Four Weeks"
        coupon="FRIDAYREACT35%x4WEEKS"
        browserTitle="Don't miss our Black Friday deal | Resubscribe now & get 35% off for 4 weeks."
        conditionsText="First four consecutive orders confirmed after restarting deliveries will have 35% off"
      />
    ))
  },
  {
    path: '/fridayresurrection-25x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="25% off on your First Two Weeks"
        coupon="FRIDAYREACT25%x2WEEKS"
        browserTitle="Don't miss our Black Friday deal | Resubscribe now & get 25% off for 2 weeks."
        conditionsText="First two consecutive orders confirmed after restarting deliveries will have 25% off"
      />
    ))
  },
  {
    path: '/resurrection-landing-25offx4freelivery',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="25% off Your First Four Weeks and Free Delivery"
        coupon="REACTIVATION25%4ORDERSFREEDELIVERY"
        browserTitle="Let's start fresh | Come back and get 25% on your first four orders and free delivery."
        conditionsText="First four orders confirmed within 60 days of restarting deliveries will have 25% off and free delivery"
      />
    ))
  },
  {
    path: '/resurrection-70x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="70% off on your First Two Weeks"
        coupon="REACTIVATION70%x2WEEKS"
        browserTitle="Let's start fresh | Come back and get 70% off your first two weeks."
        conditionsText="First two weeks after restarting deliveries will have 70% off"
      />
    ))
  },
  {
    path: '/resurrection-60x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="60% off on your First Four Weeks"
        coupon="REACTIVATION60%x4WEEKS"
        browserTitle="Let's start fresh | Come back and get 60% off your first four weeks."
        conditionsText="First four weeks after restarting deliveries will have 60% off"
      />
    ))
  },
  {
    path: '/resurrection-60x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="60% off on your First Two Weeks"
        coupon="REACTIVATION60%x2WEEKS"
        browserTitle="Let's start fresh | Come back and get 60% off your first two weeks."
        conditionsText="First two weeks after restarting deliveries will have 60% off"
      />
    ))
  },
  {
    path: '/resurrection-50x8',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="50% off on your First Eight Weeks"
        coupon="REACTIVATION50%x8WEEKS"
        browserTitle="Let's start fresh | Come back and get 50% off your first eight weeks."
        conditionsText="First eight weeks after restarting deliveries will have 50% off"
      />
    ))
  },
  {
    path: '/resurrection-50x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="50% off on your First Four Weeks"
        coupon="REACTIVATION50%x4WEEKS"
        browserTitle="Let's start fresh | Come back and get 50% off your first four weeks."
        conditionsText="First four weeks after restarting deliveries will have 50% off"
      />
    ))
  },
  {
    path: '/resurrection-50x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="50% off on your First Two Weeks"
        coupon="REACTIVATION50%x2WEEKS"
        browserTitle="Let's start fresh | Come back and get 50% off your first two weeks."
        conditionsText="First two weeks after restarting deliveries will have 50% off"
      />
    ))
  },
  {
    path: '/resurrection-40x8',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="40% off on your First Eight Weeks"
        coupon="REACTIVATION40%x8WEEKS"
        browserTitle="Let's start fresh | Come back and get 40% off your first eight weeks."
        conditionsText="First eight weeks after restarting deliveries will have 40% off"
      />
    ))
  },
  {
    path: '/resurrection-40x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="40% off on your First Four Weeks"
        coupon="REACTIVATION40%x4WEEKS"
        browserTitle="Let's start fresh | Come back and get 40% off your first four weeks."
        conditionsText="First four weeks after restarting deliveries will have 40% off"
      />
    ))
  },
  {
    path: '/resurrection-40x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="40% off on your First Two Weeks"
        coupon="REACTIVATION40%x2WEEKS"
        browserTitle="Let's start fresh | Come back and get 40% off your first two weeks."
        conditionsText="First two weeks after restarting deliveries will have 40% off"
      />
    ))
  },
  {
    path: '/resurrection-30x8',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="30% off on your First Eight Weeks"
        coupon="REACTIVATION30%x8WEEKS"
        browserTitle="Let's start fresh | Come back and get 30% off your first eight weeks."
        conditionsText="First eight weeks after restarting deliveries will have 30% off"
      />
    ))
  },
  {
    path: '/resurrection-30x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="30% off on your First Four Weeks"
        coupon="REACTIVATION30%x4WEEKS"
        browserTitle="Let's start fresh | Come back and get 30% off your first four weeks."
        conditionsText="First four weeks after restarting deliveries will have 30% off"
      />
    ))
  },
  {
    path: '/resurrection-30x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="30% off on your First Two Weeks"
        coupon="REACTIVATION30%x2WEEKS"
        browserTitle="Let's start fresh | Come back and get 30% off your first two weeks."
        conditionsText="First two weeks after restarting deliveries will have 30% off"
      />
    ))
  },
  {
    path: '/resurrection-25x4',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="25% off on your First Four Weeks"
        coupon="REACTIVATION25%x4WEEKS"
        browserTitle="Let's start fresh | Come back and get 25% off your first four weeks."
        conditionsText="First four weeks after restarting deliveries will have 25% off"
      />
    ))
  },
  {
    path: '/resurrection-20x8',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="20% off on your First Eight Weeks"
        coupon="REACTIVATION20%x8WEEKS"
        browserTitle="Let's start fresh | Come back and get 20% off your first eight weeks."
        conditionsText="First eight weeks after restarting deliveries will have 20% off"
      />
    ))
  },
  {
    path: '/resurrection-back-40x2',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="40% off on your First Two Weeks"
        coupon="CLAIM40x2"
        browserTitle="Let's start fresh | Come back and get 40% off your first two weeks."
        conditionsText="First two weeks after restarting deliveries will have 40% off"
      />
    ))
  },
  {
    path: '/resurrection-back-25x5',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="25% off on your First Five Weeks"
        coupon="CLAIM25x5"
        browserTitle="Let's start fresh | Come back and get 25% off your first five weeks."
        conditionsText="First five weeks after restarting deliveries will have 25% off"
      />
    ))
  },
  {
    path: '/resurrection-50x1',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="50% off on your First Week"
        coupon="REACTIVATION50%x1WEEK"
        browserTitle="Let’s start fresh | Come back and get 50% on your first order."
        conditionsText="First order confirmed after restarting deliveries will have 50% off"
      />
    ))
  },
  {
    path: '/resurrection-60x1',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="60% off on your First Week"
        coupon="REACTIVATION60%x1WEEK"
        browserTitle="Let’s start fresh | Come back and get 60% on your first order."
        conditionsText="First order confirmed after restarting deliveries will have 60% off"
      />
    ))
  },
  {
    path: '/resurrection-blackfriday',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="Get $400 for only $200."
        browserTitle="Let’s start fresh | Come back and get $400 for only $200."
        conditionsText="Get $400 for only $200. When you click the button below, we will give you $400 in credit for only $200 charged to your card on file. This transaction is non-refundable, and once completed, it is considered final."
        coupon={null}
        prepaidOfferId="resurrectionblackfriday2023"
        heroTitle={'Black Friday is ON.'}
        heroSubtitle={
          'Come back and enjoy more flavor, more variety, and more restaurant-quality meals to your table. When you click the button below, we will give you $400 in credit for only $200 charged to your card on file.'
        }
      />
    ))
  },
  {
    path: '/resurrection-blackfriday-econ',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="Get <strike>$13.59</strike> $8.50 per meal for 5 weeks"
        browserTitle="Let’s start fresh | Come back and get $8.50 per meal for 5 weeks."
        conditionsText="Get $8.50 per meal for 5 weeks. Great food to get you through the holidays."
        coupon={'REACTIVATIONECONOMIC'}
        heroTitle={'Black Friday is ON.'}
        heroSubtitle={
          'Come back and enjoy more flavor, more variety, and more restaurant-quality meals to your table. Pay only $8.50 per meal for 5 weeks when you rejoin today!'
        }
      />
    ))
  },
  {
    path: '/resurrection-cybermonday-econ',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="Get <strike>$13.59</strike> $8.50 per meal for 5 weeks"
        browserTitle="Let’s start fresh | Come back and get $8.50 per meal for 5 weeks."
        conditionsText="Get $8.50 per meal for 5 weeks. Great food to get you through the holidays."
        coupon={'REACTIVATIONECONOMIC'}
        heroTitle={'Cyber Monday is ON.'}
        heroSubtitle={
          'Come back and enjoy more flavor, more variety, and more restaurant-quality meals to your table. Pay only $8.50 per meal for 5 weeks when you rejoin today!'
        }
      />
    ))
  },
  {
    path: '/resurrection-850-econ',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="Get <strike>$13.59</strike> $8.50 per meal for 5 weeks"
        browserTitle="Let’s start fresh | Come back and get $8.50 per meal for 5 weeks."
        conditionsText="Get $8.50 per meal for 5 weeks. Great food to get you through the holidays."
        coupon={'REACTIVATIONECONOMIC'}
        heroTitle={'Let´s start fresh.'}
        heroSubtitle={
          'Come back and enjoy more flavor, more variety, and more restaurant-quality meals to your table. Pay only $8.50 per meal for 5 weeks when you rejoin today!'
        }
      />
    ))
  },
  {
    path: '/resurrection-landing-35offx6',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="35% off Your First Six Weeks"
        coupon="REACTIVATION35OFF6WEEKS"
        browserTitle="Let's start fresh | Come back and get 35% off Your First Six Weeks."
        conditionsText="First six orders confirmed within 60 days of restarting deliveries will have 35% off"
      />
    ))
  },
  {
    path: '/resurrection-landing-60off-20x3',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="60% off Your First Week and 20% off next Three Weeks"
        coupon="60x1AND20x3"
        browserTitle="Let's start fresh | Come back and get 60% off Your First Week and 20% off next Three Weeks."
        conditionsText="First order confirmed within 60 days of restarting deliveries will have 60% off and next 3 orders will have 20% off."
      />
    ))
  },
  {
    path: '/resurrection-landing-50off-30x3',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="50% off Your First Week and 30% off next Three Weeks"
        coupon="50x1AND30x3"
        browserTitle="Let's start fresh | Come back and get 50% off Your First Week and 30% off next Three Weeks."
        conditionsText="First order confirmed within 60 days of restarting deliveries will have 50% off and next 3 orders will have 30% off."
      />
    ))
  },
  {
    path: '/resurrection-landing-50off-20x3',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="50% off Your First Week and 20% off next Three Weeks"
        coupon="50x1AND20x3"
        browserTitle="Let's start fresh | Come back and get 50% off Your First Week and 20% off next Three Weeks."
        conditionsText="First order confirmed within 60 days of restarting deliveries will have 50% off and next 3 orders will have 20% off."
      />
    ))
  },
  {
    path: '/resurrection-landing-40off-20x3',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="40% off Your First Week and 20% off next Three Weeks"
        coupon="40x1AND20x3"
        browserTitle="Let's start fresh | Come back and get 40% off Your First Week and 20% off next Three Weeks."
        conditionsText="First order confirmed within 60 days of restarting deliveries will have 40% off and next 3 orders will have 20% off."
      />
    ))
  },
  {
    path: '/resurrection-landing-4x40',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text="40% off Your First Four Weeks"
        coupon="40X4"
        browserTitle="Let's start fresh | Come back and get 40% off Your First Four Weeks."
        conditionsText="First four orders confirmed within 60 days of restarting deliveries will have 40% off"
      />
    ))
  },
  {
    path: '/resurrection/:campaignSlug',
    component: withTracking(props => <Resurrection {...props} blackText />)
  },
  {
    path: '/resurrection',
    component: withTracking(props => (
      <Resurrection
        {...props}
        blackText
        text=""
        browserTitle="Let’s start fresh"
        conditionsText=" "
      />
    ))
  },
  {
    name: 'Autopilot',
    path: '/auto-assign-promotion',
    component: withTracking(() => <PromotionPage />),
    private: true
  },
  {
    name: 'Repeat Chefs Meals',
    path: '/repeat-chefs-meals',
    component: withLayout(withTracking(RepeatChefMealsPage)),
    private: true
  },
  {
    name: 'Download our App',
    path: '/download-our-app',
    component: withTracking(DownloadOurApp),
    private: false
  },
  {
    name: 'Download our app',
    path: RoutePaths.CONTENTTOCOMMERCE,
    component: () => <Redirect to={RoutePaths.DOWNLOAD_APP} />,
    private: false
  },
  {
    name: 'Payment Method Campaign',
    path: '/new-payment-method',
    component: withLayout(withTracking(NewPaymentMethodCampaignPage)),
    private: true
  },
  {
    label: 'Review Challenge',
    path: `${RoutePaths.REVIEW_CHALLENGE}/:id`,
    component: withLayout(withTracking(ReviewChallengePage)),
    private: true
  },
  {
    name: 'Monthly Plan',
    path: RoutePaths.MONTHLY_PLAN,
    component: withLayout(withTracking(MonthlyPlanPage)),
    private: true
  }
]

export default routes
