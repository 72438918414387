import { handleActions } from 'redux-actions'

import * as chefsActions from './actions'

const initialState = {
  currentChefs: null
}

export default handleActions(
  {
    [chefsActions.getChefsSuccess]: (state, action) => {
      return {
        ...state,
        currentChefs: action.payload.currentChefs
      }
    },
    [chefsActions.getChefsFail || chefsActions.getChefsStart]: state => {
      return {
        ...state,
        currentChefs: []
      }
    }
  },
  initialState
)
