import { useRef, useState, useEffect } from 'react'

export function useSticky(rootMargin = '-1px 0px 0px 0px') {
  const ref = useRef(null)

  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new IntersectionObserver(
      ([event]) => setIsSticky(event.intersectionRatio < 1),
      { threshold: [1], rootMargin }
    )
    observer.observe(ref.current)

    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ref, isSticky }
}
