export const CancelSubscriptionWarning = ({
  allowPlanChange = true,
  isUserB2C = true
}) => {
  const email = isUserB2C ? 'support@cookunity.com' : 'b2bsupport@cookunity.com'

  return (
    <>
      {allowPlanChange ? (
        <p className={'action-account'}>
          You will have the option to cancel your plan after your first
          delivery. Have a question? Please contact us at{' '}
          <a className="action-account-help-link" href={`mailto:${email}`}>
            {email}
          </a>
          .
        </p>
      ) : (
        <p className={'action-account'}>
          We’re here to help! To ensure the best service, please contact our
          Customer Support team to cancel your subscription{' '}
          <a className="action-account-help-link" href={`mailto:${email}`}>
            {email}
          </a>
          .
        </p>
      )}
    </>
  )
}
