import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAtom } from 'jotai'
import { CuiButton } from 'front-lib'

import { useIsMobile } from 'src/hooks/useIsMobile'
import { getSubscriptionPlan } from 'src/modules/MyPlan/selectors'
import { ChangePlanSuccessBottomSheet } from './components/ChangePlanSuccessBottomSheet'
import { ChangePlanSuccessModal } from './components/ChangePlanSuccessModal'
import { PlanOptionCard } from '../PlanOptionCard/PlanOptionCard'
import { changePlanSuccessModalAtom } from 'src/atoms/modals/changePlanSuccessModalAtom.atom'
import { cleanSubscriptionPlanStatus } from 'src/redux-api-bridge'

import './ChangePlanSuccessModalOverlay.scss'

export function ChangePlanSuccessModalOverlay() {
  const dispatch = useDispatch()
  const subscriptionPlan = useSelector(getSubscriptionPlan)
  const isMobile = useIsMobile()
  const [{ isOpen }, setChangePlanSuccessModal] = useAtom(
    changePlanSuccessModalAtom
  )
  const Modal = isMobile ? ChangePlanSuccessBottomSheet : ChangePlanSuccessModal

  useEffect(() => {
    dispatch(cleanSubscriptionPlanStatus())
    // eslint-disable-next-line
  }, [])

  function handleClose() {
    setChangePlanSuccessModal({ isOpen: false })
  }

  return (
    <Modal
      className="change-plan-success-modal"
      onClose={handleClose}
      isOpen={isOpen}>
      <div className="change-plan-success-modal--container">
        <div className="title">You have successfully modified your plan!</div>
        <div className="description">
          <div className="description--text">Your selected plan</div>
          <PlanOptionCard
            plan={subscriptionPlan}
            type="plain"
            selected={true}
          />
          <CuiButton fill="outline" onClick={handleClose}>
            Close
          </CuiButton>
        </div>
      </div>
    </Modal>
  )
}
