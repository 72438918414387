import { createAction } from 'redux-actions'

export const fetchCartForDate = createAction('FETCH_CART_FOR_DATE')
export const fetchCartForDateSucceed = createAction(
  'FETCH_CART_FOR_DATE_SUCCEED'
)
export const fetchCartForDateFailed = createAction('FETCH_CART_FOR_DATE_FAILED')
export const setCartOpen = createAction('SET_CART_IS_OPEN')
export const addProductToCart = createAction('ADD_PRODUCT_TO_CART')
export const addProductToCartSucceed = createAction(
  'ADD_PRODUCT_TO_CART_SUCCEED'
)
export const addProductToCartFailed = createAction('ADD_PRODUCT_TO_CART_FAILED')

export const removeProductFromCart = createAction('REMOVE_PRODUCT_FROM_CART')
export const removeProductFromCartSucceed = createAction(
  'REMOVE_PRODUCT_FROM_CART_SUCCEED'
)
export const removeProductFromCartFailed = createAction(
  'REMOVE_PRODUCT_FROM_CART_FAILED'
)

export const clearCart = createAction('CLEAR_CART')
export const clearCartSucceed = createAction('CLEAR_CART_SUCCEED')
export const clearCartFailed = createAction('CLEAR_CART_FAILED')
