import { FC, useEffect } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { useSelector, useDispatch } from 'react-redux'
import { getChallenge } from 'src/redux-api-bridge/challenge/selectors'
import { WiredChallengePanel } from 'src/pages/orders/components/WiredChallengePanel'
import { CHALLENGE_IDS } from 'src/constants/challenge'
import {
  fetchUserChallenges,
  fetchChallengeWithSteps
} from 'src/redux-api-bridge/challenge/effects'

import { ChangePlanModal } from 'src/pages/myPlan/components/ChangePlanModal/ChangePlanModal'
import { ChangePlanSuccessModalOverlay } from 'src/pages/myPlan/components/ChangePlanSuccessModal/ChangePlanSuccessModalOverlay'
import { changePlanSuccessModalAtom } from 'src/atoms/modals/changePlanSuccessModalAtom.atom'
import { getUpdatePlanStatus } from 'src/pages/myPlan/selectors'
import { useChangePlanModal } from 'src/hooks/useChangePlanModal'
import { toaster } from 'src/utils/toaster'
import { deflectionWithActivePromoModalAtom } from 'src/atoms/modals/deflectionWithPromoActiveModal.atom'
import { DeflectionWithPromoActiveModal } from '../DeflectionWithActivePromoModal/DeflectionWithActivePromoModal'

const Global: FC = () => {
  const dispatch = useDispatch()
  const { active } = useSelector(getChallenge)
  const showChallengePanel = active.some(
    challenge => challenge.challengeId === CHALLENGE_IDS.FIVE_MILESTONES
  )

  const updatePlanStatus = useSelector(getUpdatePlanStatus)
  const { isOpen: changePlanModalOpen } = useChangePlanModal()
  const { isOpen: isDeflectionWithActivePromoModalOpen } = useAtomValue(
    deflectionWithActivePromoModalAtom
  )
  const [changePlanSuccessModal, setChangePlanSuccessModal] = useAtom(
    changePlanSuccessModalAtom
  )

  useEffect(() => {
    if (updatePlanStatus.succeeded) {
      setChangePlanSuccessModal({ isOpen: true })
    }
    if (updatePlanStatus.error) {
      toaster.failure(
        <div>
          <span className="bold">Error:</span> An unexpected error has occurred.
          Please try again later.
        </div>,
        { closeButton: false }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlanStatus])

  useEffect(() => {
    dispatch(fetchUserChallenges())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    active.forEach(({ challengeId, enabled, variant }) => {
      if (challengeId && enabled && variant === 'treatment-1') {
        dispatch(fetchChallengeWithSteps(challengeId))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <>
      {showChallengePanel && (
        <WiredChallengePanel challengeId={CHALLENGE_IDS.FIVE_MILESTONES} />
      )}
      {changePlanSuccessModal.isOpen && <ChangePlanSuccessModalOverlay />}
      {changePlanModalOpen && <ChangePlanModal />}
      {isDeflectionWithActivePromoModalOpen && (
        <DeflectionWithPromoActiveModal />
      )}
      {}
    </>
  )
}

export default Global
