import { useFlag } from '@unleash/proxy-client-react'
import { useAtom } from 'jotai'

import { FF_CHANGE_PLAN_MODAL } from 'src/constants/feature-flags'
import { changePlanModalAtom } from 'src/atoms/modals/changePlanModalAtom.atom'

export function useChangePlanModal() {
  const isModalEnabled = useFlag(FF_CHANGE_PLAN_MODAL)
  const [{ isOpen, upgrade }, setChangePlanModal] = useAtom(changePlanModalAtom)

  function open(upgrade: boolean = false) {
    setChangePlanModal({ isOpen: true, upgrade })
  }

  function close() {
    setChangePlanModal({ isOpen: false, upgrade: false })
  }

  return {
    isModalEnabled,
    isOpen,
    isUpgrade: upgrade,
    open,
    close
  }
}
