import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'

import { useGetUserDataBeforeCancel } from 'src/api/account/getUserDataBeforCancel'
import { usePromotions } from './usePromotions'

const ID_PROMOTION_CANCELATION = 85
const ECONOMIC_PLAN_SEGMENTS = [4, 6, 8]
const PROMOTION_ECONOMIC_PLAN_IDS = [173, 176, 285]

export function useBrightback() {
  const [variables, setVariables] = useState({})
  const [userLifespan, setUserLifespan] = useState()
  const [completedOrders, setCompletedOrders] = useState(0)

  const { data } = useGetUserDataBeforeCancel()
  const { storePromotions, availablePromotions } = usePromotions()

  const plan = useSelector(state =>
    pathOr({}, ['api', 'subscription', 'userPlan'], state)
  )
  const diets = useSelector(state =>
    pathOr([], ['api', 'preferences', 'diets'], state)
  )

  const {
    addresses,
    availableDeliveryDays,
    deliveryDays,
    email,
    id,
    magento_id,
    name,
    planChanges,
    ring,
    store_id
  } = useSelector(state => pathOr({}, ['api', 'subscription', 'info'], state))

  useEffect(() => {
    if (data?.weeksSinceFirstDeliveryWhileActive) {
      setUserLifespan(data.weeksSinceFirstDeliveryWhileActive)
    }
    if (data?.countOrdersCompleted) {
      setCompletedOrders(data.countOrdersCompleted)
    }
  }, [data])

  useEffect(() => {
    if (id) {
      const brightBackVariables = {
        app_id: process.env.REACT_APP_BRIGHTBACK_ID,
        email: email,
        first_name: name,
        last_name: name,
        subscription_id: id,
        save_return_url: process.env.REACT_APP_BRIGHTBACK_SAVE_URL,
        cancel_confirmation_url: process.env.REACT_APP_BRIGHTBACK_CANCEL_URL,
        account: {
          internal_id: magento_id,
          subscription_id: id
        },
        custom: {
          offer_eligible: !isOfferCancelation(),
          subscription_plan: plan.mealsPerDelivery,
          delivery_type: ring?.is_local
            ? `Local ${ring?.shipping_days}`
            : `Regional ${ring?.shipping_days}`,
          delivery_day: deliveryDays[0].day,
          completedOrders,
          lifespan_segment: userLifespan,
          diets,
          hasEconomicPlan: ECONOMIC_PLAN_SEGMENTS.includes(plan.segmentId),
          hadEconomicPlan: hadEconomicPlan(),
          hasAvailablePromotions: hasAvailablePromotions(),
          acceptsTuesday: acceptsDeliveryDay(2),
          acceptsWednesday: acceptsDeliveryDay(3),
          acceptsThursday: acceptsDeliveryDay(4),
          ...getAddressData(addresses),
          storeId: store_id
        }
      }
      setVariables(brightBackVariables)
    }
    // eslint-disable-next-line
  }, [userLifespan, completedOrders, id])

  const getAddressData = addresses => {
    const [address] = addresses
    return address
      ? {
          zipCode: address.postcode,
          region: address.region,
          regionId: address.regionId
        }
      : {}
  }

  const isOfferCancelation = () => {
    return storePromotions?.some(
      elem => elem.promotionId === ID_PROMOTION_CANCELATION
    )
  }

  const hadEconomicPlan = () => {
    const hasEconomicPlanPromotion = storePromotions.some(promotion =>
      PROMOTION_ECONOMIC_PLAN_IDS.includes(promotion.promotionId)
    )
    const hasEconomicPlan = planChanges.some(planChange =>
      ECONOMIC_PLAN_SEGMENTS.includes(planChange?.plan?.segmentId)
    )
    return hasEconomicPlanPromotion || hasEconomicPlan
  }

  const hasAvailablePromotions = () => {
    return availablePromotions && availablePromotions.length > 0
  }

  const acceptsDeliveryDay = (indexAvailableDeliveryDays: number) => {
    if (availableDeliveryDays.length === 0) {
      return false
    }

    const isAvailable = availableDeliveryDays.some(
      availableDeliveryDay =>
        availableDeliveryDay.day === indexAvailableDeliveryDays
    )
    return isAvailable
  }

  return { variables }
}
